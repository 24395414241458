import styled from "styled-components";

export const LocationContainer = styled.div`
    position: relative;
    z-index: 2;
`;
export const Division = styled.div`
    ${({ notInRange, theme }) => {
        const backgroundColor = notInRange
            ? theme.colors.error
            : theme.colors.tertiary.normal;
        const buttonWidth = notInRange ? "100%" : "auto";
        const buttonMargin = notInRange ? "0" : "8px";
        return `
    position: relative;
    padding: 12px;
    background-color: ${backgroundColor};
    height: 60px;
    @media screen and (max-width: 768px) {
        height: auto;
    }    
    min-width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    span,
    button {
        display: inline;
    }
    span {
        color: #fff;
    }
    button {
        margin-left: 24px;

        @media screen and (max-width: 768px) {
            width: ${buttonWidth};
            margin: ${buttonMargin};
        }
    }
}
`;
    }}
`;
export const StyledDiv = styled.span`
    font-weight: 500;
    display: flex !important;
    justify-content: center;
    flex-direction: column !important;
    .phone {
        color: #fff;
        text-decoration: underline !important;
        display: block;
        &:hover,
        &:focus {
            color: #fff;
        }
    }
    @media screen and (max-width: 600px) {
        line-height: unset;
    }
    @media screen and (max-width: 320px) {
        font-size: 12px;
    }
    .haserror {
        @media screen and (max-width: 600px) {
            margin-bottom: 10px;
            display: block;
        }
        b {
            @media screen and (max-width: 600px) {
                display: inline-block;
                text-align: left;
            }
        }
    }
    b {
        @media screen and (max-width: 600px) {
            display: block;
            text-align: left;
        }
    }
`;
