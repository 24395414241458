/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    saveLocationOnCookie,
    saveGUIDCookie,
    removeGUIDCookie
} from "utils/helpers";
import {
    getSelectedLocation,
    getAllUserLocations,
    getSelectedLocationPhone
} from "api/location";
import {
    setSelectedLocation,
    setAllLocations,
    setStorePhone
} from "store/modules/location";
import { getUser } from "api/auth";
import { authenticated } from "store/modules/authentication";

import { enqueueModal, closeModal } from "store/modules/modal";
import DeliveryAddressModal from "./DeliveryAddressModal/DeliveryAddressModal";
import HeaderLocation from "./HeaderLocation";

const HeaderLocationContainer = React.memo(props => {
    const [initialized, setInitialized] = useState(false);
    const [notInRange, setNotInRange] = useState(false);
    const {
        dispatch,
        isAuthed,
        selectedLocation,
        allLocations,
        storePhone
    } = props;

    const handleNotInRangeStatus = data => {
        if (data && data.meta) {
            if (data.meta.code === "DELIVERY_NOT_IN_RANGE") {
                setNotInRange(true);
            } else {
                saveLocationOnCookie(data);
                setNotInRange(false);
            }
            if (data.meta.user_guid) {
                saveGUIDCookie(data.meta.user_guid);
            } else {
                removeGUIDCookie();
            }
        }
    };
    const handleGetSelectedLocation = () => {
        getSelectedLocation().then(data => {
            dispatch(setSelectedLocation(data));
            handleNotInRangeStatus(data);
        });
    };
    const handleGetSelectedLocationPhone = () => {
        getSelectedLocationPhone().then(data => {
            dispatch(setStorePhone(data));
        });
    };

    const handleGetAllUserLocations = () => {
        getAllUserLocations().then(data => {
            dispatch(setAllLocations(data));
        });
    };
    const updateLocation = async data => {
        await dispatch(setSelectedLocation(data));
        handleNotInRangeStatus(data);
        handleGetSelectedLocationPhone();
        if (isAuthed) {
            getUser().then(user => {
                dispatch(authenticated(user));
            });
        }
        dispatch(closeModal());
    };
    useEffect(() => {
        if (!initialized) {
            handleGetSelectedLocation();
            handleGetSelectedLocationPhone();
            updateLocation();
            setInitialized(true);
        }
    });
    const showCurrentAddress = () => {
        let address;
        if (
            selectedLocation &&
            selectedLocation.address !== undefined &&
            selectedLocation.address !== null &&
            selectedLocation.address !== ""
        ) {
            const filteredAddress = selectedLocation.address.split(", ");
            const addressSecondPart =
                filteredAddress[1] !== undefined
                    ? `, ${filteredAddress[1]}`
                    : "";
            address = `${filteredAddress[0]} ${addressSecondPart}`;
        } else if (
            selectedLocation &&
            selectedLocation.zip !== undefined &&
            selectedLocation.zip !== null &&
            selectedLocation.zip !== ""
        ) {
            address = selectedLocation.zip;
        } else if (
            selectedLocation &&
            selectedLocation.city !== undefined &&
            selectedLocation.city !== null &&
            selectedLocation.city !== ""
        ) {
            address = selectedLocation.city;
        } else {
            address = "";
        }
        return address;
    };
    const handleOnShowMapClick = () => {
        dispatch(
            enqueueModal({
                title: "Where are you?",
                classes: "delivery_address_modal",
                data: (
                    <DeliveryAddressModal
                        allLocations={allLocations}
                        currentLocation={showCurrentAddress()}
                        notInRange={notInRange}
                        selectedLocation={selectedLocation}
                        updateLocation={updateLocation}
                    />
                )
            })
        );
    };
    return (
        <HeaderLocation
            currentLocation={showCurrentAddress()}
            notInRange={notInRange}
            selectedLocation={selectedLocation}
            storePhone={storePhone}
            onUpdateLocationClick={handleOnShowMapClick}
        />
    );
});
HeaderLocationContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isAuthed: PropTypes.bool.isRequired,
    selectedLocation: PropTypes.objectOf.isRequired,
    storePhone: PropTypes.objectOf.isRequired,
    allLocations: PropTypes.arrayOf.isRequired
};
const mapStateToProps = ({ location }) => ({
    selectedLocation: location.selectedLocation,
    storePhone: location.storePhone,
    allLocations: location.allLocations
});
export default connect(mapStateToProps)(HeaderLocationContainer);
