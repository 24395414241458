import { googleMapToken } from "config/constants";
import request from "utils/request";
/**
 * Get User Selected Location
 * @returns {Promise}
 */
export function getSelectedLocation() {
    return request(`user/locations/selected`, null, "GET");
}
/**
 * Get Store Phone
 * @returns {Promise}
 */
export function getSelectedLocationPhone() {
    return request("user/locations/phone", null, "GET");
}
/**
 * Get All users Locations
 * @returns {Promise}
 */
export function getAllUserLocations() {
    return request(`user/locations`, null, "GET");
}

/**
 * Update Selected Location
 * @param {Object} data
 * @returns {Promise}
 */
export function updateSelectedLocation(data) {
    return request("user/locations/selected", data, "POST");
}
export function checkSelectedLocationStatus(data) {
    return request("geolocations/delivery_in_range", data, "POST");
}

/**
 * Find Location Details
 * @param {String} string
 * @returns {Promise}
 */
export function locationDetails(string) {
    const Data = fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?${string}&key=${googleMapToken}`
    ).then(data => data.json());
    return Data;
}
