import request from "utils/request";

/**
 * Add new Item to cart
 * @param {Object} data
 * @returns {Promise}
 */
export function addItem(data) {
    return request("add_to_cart", data, "POST");
}

/**
 * Get Cart Items
 * @returns {Promise}
 */
export function viewCart() {
    return request("view_cart", null, "GET");
}

/**
 * Remove Cart Items
 * @param {Object} data
 * @returns {Promise}
 */
export function deleteItem(data) {
    return request("remove_cart_item", data, "DELETE");
}

/**
 * Checkout
 * @param {Object} data
 * @returns {Promise}
 */
export function checkoutRequest(data) {
    return request("checkout", data, "POST");
}

/**
 * Get one order
 * @param {String} id
 * @returns {Promise}
 */
export function getOneOrder(id) {
    return request(`orders/${id}`, null, "GET");
}

/**
 * Validate promo codes
 * @param {String} code
 * @returns {Promise}
 */
export function validatePromoCode(code) {
    return request(`validate_promo_code?promo_code=${code}`, null, "GET");
}

/**
 * Get delivery address cost
 * @param {String} address
 * @returns {Promise}
 */
export function getDeliveryCost(data) {
    return request(`get_delivery_cost`, data, "GET");
}

/**
 * Cancel order
 * @param {Object} data
 * @returns {Promise}
 */
export function cancelOrder(data) {
    return request("orders/cancel", data, "POST");
}

/**
 * Get Influencer Info
 * @returns {Promise}
 */
export function getInfluencerInfo() {
    return request("influencer_details", null, "GET");
}

/**
 * Get Electronic Payments
 * @returns {Promise}
 */
export function getElectronicPayments() {
    return request("electronic_payments", null, "GET");
}
