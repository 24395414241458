import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Img } from "components/ui";

const SearchItem = ({ item }) => {
    return (
        <Items href={`/shop/product/${item.id}`}>
            <Img alt={item.name} height="32px" src={item.img} width="32px" />
            <ItemName>{item.name}</ItemName>
            {item.brand && <ItemBrand>{item.brand.name}</ItemBrand>}
        </Items>
    );
};
const Items = styled.a`
    display: block;
    padding: 8px;
    &:hover {
        background: var(--gray2);
    }
`;
const ItemName = styled.span`
    display: block;
    font-size: 16px;
    margin-bottom: 4px;
    line-height: 16px;
    color: var(--black);
`;
const ItemBrand = styled.span`
    display: block;
    color: var(--gray5);
`;
SearchItem.propTypes = {
    item: PropTypes.objectOf.isRequired
};
export default SearchItem;
