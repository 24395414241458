const SET_USER_INFO = "SET_USER_INFO";

export const setUserInfo = data => ({
    type: SET_USER_INFO,
    data
});

const initialState = {
    userInfo: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_INFO:
            return {
                ...state,
                userInfo: action.data
            };

        default:
            return state;
    }
};
