import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { IoIosClose } from "react-icons/io";
import { Button, Img } from "components/ui";
import withLoading from "components/withLoading/withLoading";
import { noImage } from "config/constants";
import "./cart.scss";

const Cart = React.memo(props => {
    const {
        items,
        isLoading,
        removingProcess,
        removeCartItem,
        totalAmount,
        goToCheckout
    } = props;

    useEffect(() => {
        document.body.classList.add("cart-is-open");
        return () => {
            document.body.classList.remove("cart-is-open");
        };
    }, []);
    return (
        <>
            {isLoading}
            <div className="cart">
                <div className="cart__header">
                    <h2 className="cart__header--title">Your Cart</h2>
                </div>

                <div
                    className={cn("cart__body", {
                        "d-none": items.length === 0
                    })}
                >
                    {items.map(item => {
                        const {
                            cost,
                            quantity,
                            product: { image_url: imageUrl, name }
                        } = item;
                        const picture =
                            imageUrl.length > 0 && imageUrl[0] !== null
                                ? imageUrl[0]
                                : noImage;
                        return (
                            <div key={item.id} className="cart__body--product">
                                <div className="cart__body--product-img">
                                    <Img
                                        alt=""
                                        height="120"
                                        src={
                                            picture.length > 0
                                                ? `${picture}`
                                                : ""
                                        }
                                        width="120"
                                    />
                                </div>
                                <div className="cart__body--product-details">
                                    <h6 className="cart__body--product-title">
                                        {name}
                                    </h6>
                                    <span className="cart__body--product-cost">
                                        ${cost && cost.toFixed(2)}
                                    </span>
                                    <span className="cart__body--product-quantity">
                                        {quantity}
                                    </span>
                                </div>
                                <div
                                    className="cart__body--product-remove"
                                    disabled={removingProcess === item.id}
                                    onClick={() => removeCartItem(item.id)}
                                >
                                    <span
                                        aria-hidden="true"
                                        className={cn(
                                            "spinner-border spinner-border-sm",
                                            {
                                                "d-none":
                                                    removingProcess !== item.id
                                            }
                                        )}
                                        role="status"
                                    />
                                    <IoIosClose
                                        className={cn({
                                            "sr-only":
                                                removingProcess === item.id
                                        })}
                                        size="38"
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div
                    className={cn("cart__empty", {
                        "d-none": items.length !== 0
                    })}
                >
                    Your cart is empty.
                </div>

                <div className="cart__footer">
                    <div className="cart__footer--divider" />
                    <div className="cart__footer--totalcost">
                        <span>Subtotal:</span>
                        <span>${totalAmount.toFixed(2)}</span>
                    </div>
                    <Button
                        className={cn("cart__footer--btn", {
                            "d-none": items.length === 0
                        })}
                        onClick={goToCheckout}
                    >
                        Continue to checkout
                    </Button>
                </div>
            </div>
        </>
    );
});
Cart.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf.isRequired,
    removingProcess: PropTypes.number.isRequired,
    removeCartItem: PropTypes.func.isRequired,
    totalAmount: PropTypes.number.isRequired,
    goToCheckout: PropTypes.func.isRequired
};
export default withLoading(Cart);
