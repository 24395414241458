import React from "react";
import { toast } from "react-toastify";
import {
    InfoToast,
    WarningToast,
    SuccessToast,
    ErrorToast
} from "components/Toast/Toast";

export const success = options => {
    return toast.success(<SuccessToast {...options} />);
};

export const error = options => {
    return toast.error(<ErrorToast {...options} />);
};

export const warn = options => {
    return toast.warning(<WarningToast {...options} />);
};

export const info = options => {
    return toast.info(<InfoToast {...options} />);
};

export default info;
