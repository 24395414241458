/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import Textarea from "@logickit/logickit-decore/build/lib/components/Textarea";
import styled from "styled-components";

const StyledTextarea = styled(Textarea)`
    border: none !important;
    background-color: #eee !important;
    padding: 1.5rem 0.8rem !important;

    min-height: 90px;
    max-height: 200px;
    min-width: 100%;
`;

export default React.memo(props => <StyledTextarea {...props} />);
