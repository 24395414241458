/* eslint-disable import/prefer-default-export */
/* eslint-disable react/prop-types */
import React from "react";

function Icon(props) {
    return <svg className="icon" {...props} />;
}
function Lines(props) {
    const { color, stroke } = props;
    return (
        <g
            data-name="Lines"
            id="line"
            style={{
                fill: `${color}`,
                stroke: `${stroke}`
            }}
            {...props}
        />
    );
}
function Path(props) {
    return <path {...props} />;
}

export const IconFilter = props => {
    const { height, width, color, stroke } = props;
    return (
        <Icon height={height} name="filter" viewBox="0 0 16 13" width={width}>
            <Lines stroke={stroke}>
                <Path
                    d="M8.43262 0H7.56774V2.78571H6.0542V6.12857H7.56774V13H8.43262V6.12857H9.94617V2.78571H8.43262V0ZM9.08129 5.38571H6.91908V3.52857H9.08129V5.38571Z"
                    fill={color}
                    name="filter"
                />
                <Path
                    d="M13.6215 13H14.4864V9.65714H16V6.31429H14.4864V0H13.6215V6.31429H12.1079V9.65714H13.6215V13ZM12.9728 8.91429V7.05714H15.1351V8.91429H12.9728Z"
                    fill={color}
                    name="filter"
                />
                <Path
                    d="M2.37842 13V9.65714H3.89197V6.31429H2.37842V0H1.51354V6.31429H0V9.65714H1.51354V13H2.37842ZM0.864882 8.91429V7.05714H3.02709V8.91429H0.864882Z"
                    fill={color}
                    name="filter"
                />
            </Lines>
        </Icon>
    );
};
export const IconClose = props => {
    const { height, width, color } = props;
    return (
        <Icon height={height} viewBox="0 0 22 22" width={width}>
            <Path
                d="M20 2L18 0L10 8L2 0L0 2L8 10L0 18L2 20L10 12L18 20L20 18L12 10L20 2Z"
                fill={color}
            />
        </Icon>
    );
};
export const IconUser = props => {
    const { height = 25, width = 25, color = "black" } = props;
    return (
        <Icon height={height} viewBox="0 0 16 16" width={width}>
            <Path
                d="M7.94206 8.62125C7.95863 8.62125 7.9752 8.62125 7.99508 8.62125C8.0017 8.62125 8.00833 8.62125 8.01496 8.62125C8.0249 8.62125 8.03815 8.62125 8.04809 8.62125C9.01889 8.60468 9.80415 8.26341 10.384 7.61069C11.6596 6.17271 11.4475 3.7076 11.4244 3.47235C11.3415 1.70636 10.5066 0.861462 9.8174 0.467178C9.30383 0.172292 8.70412 0.0132533 8.03484 0H8.01164C8.00833 0 8.0017 0 7.99839 0H7.97851C7.61073 0 6.88843 0.0596397 6.19595 0.453924C5.50015 0.848209 4.65194 1.6931 4.56911 3.47235C4.54591 3.7076 4.33386 6.17271 5.60949 7.61069C6.18601 8.26341 6.97126 8.60468 7.94206 8.62125ZM5.45376 3.55519C5.45376 3.54525 5.45708 3.53531 5.45708 3.52868C5.56642 1.15303 7.25289 0.897909 7.9752 0.897909H7.98845C7.99508 0.897909 8.00502 0.897909 8.01496 0.897909C8.90955 0.917788 10.4304 1.28225 10.5331 3.52868C10.5331 3.53862 10.5331 3.54856 10.5364 3.55519C10.5397 3.57838 10.7716 5.83144 9.718 7.0176C9.30052 7.48809 8.74389 7.72003 8.01164 7.72665C8.00502 7.72665 8.0017 7.72665 7.99508 7.72665C7.98845 7.72665 7.98514 7.72665 7.97851 7.72665C7.24958 7.72003 6.68963 7.48809 6.27546 7.0176C5.22514 5.83806 5.45045 3.57507 5.45376 3.55519Z"
                fill={color}
            />
            <Path
                d="M14.8039 12.7098C14.8039 12.7065 14.8039 12.7032 14.8039 12.6999C14.8039 12.6734 14.8006 12.6469 14.8006 12.6171C14.7807 11.961 14.7377 10.427 13.2997 9.9366C13.2898 9.93329 13.2765 9.92997 13.2666 9.92666C11.7723 9.54563 10.5298 8.68416 10.5165 8.67422C10.3144 8.53175 10.0361 8.58145 9.89361 8.78356C9.75113 8.98568 9.80083 9.26399 10.0029 9.40647C10.0593 9.44623 11.378 10.364 13.028 10.7881C13.8 11.0631 13.8862 11.8881 13.9093 12.6436C13.9093 12.6734 13.9093 12.6999 13.9127 12.7264C13.916 13.0246 13.8961 13.4852 13.8431 13.7502C13.3063 14.0551 11.2024 15.1087 8.0017 15.1087C4.81429 15.1087 2.69709 14.0517 2.15701 13.7469C2.104 13.4819 2.08081 13.0213 2.08744 12.7231C2.08744 12.6966 2.09075 12.6701 2.09075 12.6403C2.11394 11.8848 2.20009 11.0598 2.97209 10.7848C4.62212 10.3607 5.94082 9.4396 5.99715 9.40315C6.19926 9.26068 6.24896 8.98236 6.10649 8.78025C5.96401 8.57814 5.6857 8.52844 5.48358 8.67091C5.47033 8.68085 4.23446 9.54231 2.73353 9.92335C2.72028 9.92666 2.71034 9.92997 2.7004 9.93328C1.26242 10.427 1.21935 11.961 1.19947 12.6138C1.19947 12.6436 1.19947 12.6701 1.19615 12.6966C1.19615 12.6999 1.19615 12.7032 1.19615 12.7065C1.19284 12.8788 1.18953 13.7635 1.36513 14.2075C1.39827 14.2936 1.45791 14.3665 1.53742 14.4162C1.63682 14.4825 4.0191 16 8.00502 16C11.9909 16 14.3732 14.4792 14.4726 14.4162C14.5488 14.3665 14.6118 14.2936 14.6449 14.2075C14.8106 13.7668 14.8073 12.8821 14.8039 12.7098Z"
                fill={color}
            />
        </Icon>
    );
};
export const IconCart = props => {
    const { height = 25, width = 25, color = "black" } = props;
    return (
        <Icon height={height} viewBox="0 0 14 16" width={width}>
            <Path
                d="M13.4 13.8307L12.4839 3.51411C12.4642 3.28507 12.2712 3.11166 12.0454 3.11166H10.1608C10.1346 1.39059 8.72762 0 7.00001 0C5.27241 0 3.86545 1.39059 3.83928 3.11166H1.95461C1.72558 3.11166 1.5358 3.28507 1.51617 3.51411L0.600013 13.8307C0.600013 13.8438 0.596741 13.8569 0.596741 13.8699C0.596741 15.0446 1.67322 16 2.99838 16H11.0016C12.3268 16 13.4033 15.0446 13.4033 13.8699C13.4033 13.8569 13.4033 13.8438 13.4 13.8307ZM7.00001 0.883436C8.2401 0.883436 9.25114 1.87812 9.27732 3.11166H4.72271C4.74889 1.87812 5.75993 0.883436 7.00001 0.883436ZM11.0016 15.1166H2.99838C2.16729 15.1166 1.49326 14.5669 1.48018 13.8896L2.35707 3.99836H3.836V5.33988C3.836 5.58528 4.03232 5.78159 4.27772 5.78159C4.52312 5.78159 4.71944 5.58528 4.71944 5.33988V3.99836H9.27732V5.33988C9.27732 5.58528 9.47363 5.78159 9.71903 5.78159C9.96443 5.78159 10.1608 5.58528 10.1608 5.33988V3.99836H11.6397L12.5199 13.8896C12.5068 14.5669 11.8295 15.1166 11.0016 15.1166Z"
                fill={color}
            />
        </Icon>
    );
};
export const IconHome = props => {
    const { height = 25, width = 25 } = props;
    return (
        <Icon height={height} viewBox="0 0 24 24" width={width}>
            <Path
                clipRule="evenodd"
                d="M23.4169 9.78144L13.0605 0.415587C12.7631 0.147307 12.3854 0 11.9951 0C11.6047 0 11.2271 0.147307 10.9297 0.415587L0.57329 9.78144C0.320868 10.01 0.138854 10.3137 0.0507956 10.6532C-0.0372623 10.9927 -0.0273217 11.3524 0.079318 11.6859C0.185958 12.0193 0.384404 12.3112 0.649008 12.5237C0.913611 12.7362 1.2322 12.8596 1.56363 12.878V22.238C1.56303 22.703 1.73593 23.1493 2.04452 23.4794C2.3531 23.8096 2.7723 23.9968 3.21053 24H20.7796C21.2179 23.9968 21.6371 23.8096 21.9456 23.4794C22.2542 23.1493 22.4271 22.703 22.4265 22.238V12.878C22.7579 12.8596 23.0766 12.7362 23.3412 12.5237C23.6058 12.3112 23.8042 12.0193 23.9108 11.6859C24.0175 11.3524 24.0274 10.9927 23.9394 10.6532C23.8513 10.3137 23.6693 10.01 23.4169 9.78144ZM11.4478 22.2439H10.3498V16.9756H13.6436V22.2439H11.4478ZM20.7813 11.122V22.2439H15.2911V16.9756C15.2917 16.7455 15.2496 16.5175 15.1672 16.3046C15.0847 16.0918 14.9635 15.8983 14.8105 15.7352C14.6575 15.5721 14.4757 15.4426 14.2754 15.3541C14.0752 15.2656 13.8605 15.2199 13.6436 15.2195H10.3498C10.1329 15.2199 9.91824 15.2656 9.71802 15.3541C9.51781 15.4426 9.33599 15.5721 9.18299 15.7352C9.02998 15.8983 8.90877 16.0918 8.8263 16.3046C8.74384 16.5175 8.70173 16.7455 8.70238 16.9756V22.2439H3.21053V11.122H1.63867L11.9951 1.7561L22.3515 11.122H20.7796H20.7813Z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </Icon>
    );
};
export const IconCatalog = props => {
    const { height = 24, width = 24 } = props;
    return (
        <Icon fill="white" height={height} viewBox="0 0 24 24" width={width}>
            <circle
                cx="12"
                cy="12"
                r="2"
                stroke="currentColor"
                strokeWidth="2"
            />
            <circle cx="3" cy="3" r="2" stroke="currentColor" strokeWidth="2" />
            <circle
                cx="12"
                cy="3"
                r="2"
                stroke="currentColor"
                strokeWidth="2"
            />
            <circle
                cx="21"
                cy="3"
                r="2"
                stroke="currentColor"
                strokeWidth="2"
            />
            <circle
                cx="3"
                cy="12"
                r="2"
                stroke="currentColor"
                strokeWidth="2"
            />
            <circle
                cx="21"
                cy="12"
                r="2"
                stroke="currentColor"
                strokeWidth="2"
            />
            <circle
                cx="3"
                cy="21"
                r="2"
                stroke="currentColor"
                strokeWidth="2"
            />
            <circle
                cx="12"
                cy="21"
                r="2"
                stroke="currentColor"
                strokeWidth="2"
            />
            <circle
                cx="21"
                cy="21"
                r="2"
                stroke="currentColor"
                strokeWidth="2"
            />
        </Icon>
    );
};
export const IconPeople = props => {
    const { height = 24, width = 24 } = props;
    return (
        <Icon height={height} viewBox="0 0 24 24" width={width}>
            <Path
                d="M14.5 7.25C14.5 11 12.25 14 10 14C7.75 14 5.5 11 5.5 7.25C5.5 3.5 7 1.25 10 1.25C13 1.25 14.5 3.5 14.5 7.25ZM1 21.5H19C19 14.75 14.5 14 10 14C5.5 14 1 14.75 1 21.5Z"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
            />
        </Icon>
    );
};
export const IconOrders = props => {
    const { height = 24, width = 24 } = props;
    return (
        <Icon height={height} viewBox="0 0 24 24" width={width}>
            <path
                d="M11.9083 0.00529899C11.8367 0.0180166 11.7664 0.0433354 11.7 0.0802851L0.5 5.90778C0.206718 6.06033 0 6.45447 0 6.86118C0 7.26788 0.206718 7.66202 0.5 7.81457L3.60833 9.43213L0.5 11.0497C0.206726 11.2022 0 11.5964 0 12.0031C0 12.4098 0.206726 12.8039 0.5 12.9565L3.60833 14.574L0.5 16.1916C0.206734 16.3441 0 16.7383 0 17.145C0 17.5517 0.206734 17.9458 0.5 18.0984L11.7 23.9259C11.8901 24.0247 12.1099 24.0247 12.3 23.9259L23.5 18.0984C23.7933 17.9458 24 17.5517 24 17.145C24 16.7383 23.7933 16.3441 23.5 16.1916L20.3917 14.574L23.5 12.9565C23.7933 12.8039 24 12.4098 24 12.0031C24 11.5964 23.7933 11.2022 23.5 11.0497L20.3917 9.43213L23.5 7.81457C23.7933 7.66202 24 7.26788 24 6.86118C24 6.45447 23.7933 6.06033 23.5 5.90778L12.3 0.0802851C12.177 0.0138448 12.041 -0.0121972 11.9083 0.00529899ZM12 2.14776L21.0667 6.86118L12 11.5746L2.93333 6.86118L12 2.14776ZM5.74167 10.5462L11.7 13.6421C11.8901 13.7409 12.1099 13.7409 12.3 13.6421L18.2583 10.5462L21.0667 12.0031L12 16.7165L2.93333 12.0031L5.74167 10.5462ZM5.74167 15.6881L11.7 18.784C11.8901 18.8828 12.1099 18.8828 12.3 18.784L18.2583 15.6881L21.0667 17.145L12 21.8584L2.93333 17.145L5.74167 15.6881Z"
                fill="currentColor"
            />
        </Icon>
    );
};
export const IconLogOut = props => {
    const { height = 24, width = 31 } = props;
    return (
        <Icon height={height} viewBox="0 0 24 24" width={width}>
            <path
                d="M16.1309 18C15.4713 18 14.9316 18.54 14.9316 19.2V21.6H2.39865V2.4H14.9316V4.8C14.9316 5.46 15.4713 6 16.1309 6C16.7905 6 17.3302 5.46 17.3302 4.8V2.4C17.3302 1.08 16.2508 0 14.9316 0H2.39865C1.07939 0 0 1.08 0 2.4V21.6C0 22.92 1.07939 24 2.39865 24H14.9316C16.2508 24 17.3302 22.92 17.3302 21.6V19.2C17.3302 18.54 16.7905 18 16.1309 18Z"
                fill="currentColor"
            />
            <path
                d="M27.6657 11.16L22.7185 6.26996C22.4786 6.05996 22.1488 5.96996 21.849 5.99996C21.5192 6.02996 21.2493 6.17996 21.0394 6.41996C20.6497 6.86996 20.6796 7.52996 21.0994 8.03996L23.9178 10.83H11.9546C11.2949 10.83 10.7552 11.37 10.7552 12.03C10.7552 12.69 11.2949 13.23 11.9546 13.23H23.8878L21.1294 15.93C20.8895 16.14 20.7396 16.44 20.7096 16.74C20.6796 17.07 20.7696 17.37 20.9795 17.61C21.1893 17.85 21.4892 18 21.789 18.03C21.819 18.03 21.849 18.03 21.879 18.03C22.1488 18.03 22.4486 17.94 22.6585 17.76C22.7185 17.73 22.7485 17.67 22.7485 17.67L27.6357 12.84C27.8756 12.6 27.9955 12.3 27.9955 12C28.0255 11.67 27.9056 11.37 27.6657 11.16Z"
                fill="currentColor"
            />
        </Icon>
    );
};
export const IconArrow = props => {
    const { height = 23, width = 20, color = "black" } = props;
    return (
        <Icon fill="none" height={height} viewBox="0 0 18 10" width={width}>
            <path
                d="M1 1L9 9L17 1"
                stroke={color}
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                strokeWidth="2"
            />
        </Icon>
    );
};
