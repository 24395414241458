import { darken, lighten, rgba } from "polished";
import { colors, sizes } from "./_baseTheme";

const primary = "rgba(0,0,0,0)";
const secondary = "rgba(0,0,0,0)";
const tertiary = "rgba(0,0,0,0)";

const greenTheme = {
    colors: {
        primary: {
            normal: primary,
            hover: lighten(0.1, primary),
            active: darken(0.1, primary),
            outline: {
                background: rgba(primary, 0.04)
            }
        },
        secondary: {
            normal: secondary,
            hover: lighten(0.1, secondary),
            active: darken(0.1, secondary),
            outline: {
                background: rgba(secondary, 0.04)
            }
        },
        tertiary: {
            normal: tertiary,
            hover: lighten(0.1, tertiary),
            active: darken(0.1, tertiary),
            outline: {
                background: rgba(tertiary, 0.04)
            }
        },
        footerBackground: tertiary,
        shopMobileFilterForm: darken(0.03, tertiary),
        ...colors
    },
    ...sizes
};

export default greenTheme;
