import React, { Fragment } from "react";
import { Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";

const HeaderNav = React.memo(() => {
    return (
        <Fragment>
            <Nav navbar className="header__nav">
                <NavItem className="header__nav-item">
                    <Link className="header__nav-link" to="/shop">
                        Explore
                    </Link>
                </NavItem>
            </Nav>
        </Fragment>
    );
});

export default HeaderNav;
