/* eslint-disable new-cap */
import { ApolloClient } from "apollo-boost";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { apiUrl } from "config/constants";

const LOGIN_TOKEN_STORAGE_KEY = "LoginToken";
const httpLink = new createHttpLink({ uri: `${apiUrl}/graphql` });

// set token autherization to headers
const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem(LOGIN_TOKEN_STORAGE_KEY);

    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : ""
        }
    };
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: "network-only",
        errorPolicy: "ignore"
    },
    query: {
        fetchPolicy: "network-only",
        errorPolicy: "all"
    }
};

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions
});

export default client;
