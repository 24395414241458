import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Img } from "components/ui";
import { handleLogout } from "store/modules/authentication";
import _ from "lodash";
import PropTypes from "prop-types";
import {
    IconHome,
    IconCatalog,
    IconPeople,
    IconOrders,
    IconLogOut,
    IconArrow
} from "components/ui/Icon";
import cn from "classnames";
import Burger from "./Burger";
import StyledMenu from "./Menu.styled";

const MenuMob = React.memo(props => {
    const [openCateg, setOpenCateg] = useState(false);
    const {
        open,
        isPage,
        categories,
        closeMenu,
        setOpen,
        userInfo,
        logo,
        isAuthed,
        openLoginModal,
        dispatch,
        searchCategory
    } = props;
    const handleSignOut = () => {
        dispatch(handleLogout());
    };

    return (
        <StyledMenu open={open} {...props}>
            <div className="top_mobile_menu">
                <div className="logo-button">
                    <Img alt="" lazy={false} src={logo} width="80" />
                    <Burger open={open} setOpen={setOpen} />
                </div>
                {!isAuthed ? (
                    <div className="d-flex justify-content-between">
                        <Button
                            outline
                            className="header__account-btn button"
                            style={{ marginRight: 16 }}
                            onClick={() => {
                                openLoginModal("register");
                                closeMenu();
                            }}
                        >
                            Register
                        </Button>
                        <Button
                            className="header__account-btn"
                            onClick={() => {
                                openLoginModal("login");
                                closeMenu();
                            }}
                        >
                            Log In
                        </Button>
                    </div>
                ) : (
                    <div className="user-name">
                        {userInfo &&
                            `${userInfo.first_name} ${userInfo.last_name}`}
                    </div>
                )}
            </div>
            <Link
                className={cn("link-mobile", {
                    "active-link": isPage === "/"
                })}
                to="/"
            >
                <span className="icon-link">
                    <IconHome color="black" />
                </span>
                <span>Home</span>
            </Link>
            <div
                className={cn("link-mobile catalog", {
                    "active-link": isPage === "/shop"
                })}
                onClick={() => {
                    setOpenCateg(!openCateg);
                }}
            >
                <Link
                    to="/shop"
                    onClick={e => {
                        e.stopPropagation();
                        closeMenu();
                    }}
                >
                    <span className="icon-link">
                        <IconCatalog color="black" />
                    </span>
                    <span>Store Catalog</span>
                </Link>
                <span
                    className={cn("icon-link-arrow pointer", {
                        "active-arrow": openCateg === true
                    })}
                >
                    <IconArrow color="black" />
                </span>
            </div>
            {!_.isEmpty(categories) && (
                <ul
                    className={cn("categories-mobile-menu", {
                        "open-categ": openCateg === true
                    })}
                >
                    {categories.map(cats => (
                        <li key={cats.id}>
                            <div
                                className={cn("link-mobile", {
                                    "active-link":
                                        searchCategory ===
                                        `?category_id=${cats.id}`,
                                    "full-width": _.isEmpty(cats.children)
                                })}
                                onClick={e => {
                                    e.currentTarget.classList.toggle(
                                        "open-subcategories"
                                    );
                                }}
                            >
                                <Link
                                    to={`/shop?category_id=${cats.id}`}
                                    onClick={e => {
                                        e.stopPropagation();
                                        closeMenu();
                                    }}
                                >
                                    {cats.name}
                                </Link>
                                {!_.isEmpty(cats.children) > 0 && (
                                    <span className="icon-link-arrow pointer">
                                        <IconArrow color="black" />
                                    </span>
                                )}
                            </div>
                            {!_.isEmpty(cats.children) && (
                                <ul className="categories-mobile-menu">
                                    {cats.children.map(subCats => (
                                        <li key={subCats.id}>
                                            <Link
                                                className={cn("link-mobile", {
                                                    "active-link":
                                                        searchCategory ===
                                                        `?category_id=${subCats.id}`
                                                })}
                                                to={`/shop?category_id=${subCats.id}`}
                                                onClick={() => {
                                                    closeMenu();
                                                }}
                                            >
                                                <span>{subCats.name}</span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            )}
            {isAuthed && (
                <>
                    <Link
                        className={cn("link-mobile", {
                            "active-link": isPage === "/myaccount"
                        })}
                        to="/myaccount"
                    >
                        <span className="icon-link">
                            <IconPeople color="black" />
                        </span>
                        <span>Profile</span>
                    </Link>
                    <Link
                        className={cn("link-mobile", {
                            "active-link": isPage === "/orders"
                        })}
                        to="/orders"
                    >
                        <span className="icon-link">
                            <IconOrders color="black" />
                        </span>
                        <span>Orders</span>
                    </Link>
                </>
            )}
            <Link
                className={cn("link-mobile contact", {
                    "active-link": isPage === "/contact"
                })}
                to="/contact"
            >
                <span>Contact Us</span>
            </Link>
            {isAuthed && (
                <a
                    className="link-mobile logOut"
                    href="#"
                    onClick={() => handleSignOut()}
                >
                    <span className="icon-link">
                        <IconLogOut />
                    </span>
                    <span>Log Out</span>
                </a>
            )}
        </StyledMenu>
    );
});

MenuMob.propTypes = {
    open: PropTypes.bool.isRequired,
    isPage: PropTypes.string.isRequired,
    categories: PropTypes.arrayOf.isRequired,
    closeMenu: PropTypes.func.isRequired,
    openLoginModal: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    userInfo: PropTypes.objectOf.isRequired,
    logo: PropTypes.string.isRequired,
    searchCategory: PropTypes.string.isRequired,
    isAuthed: PropTypes.bool.isRequired
};

export default MenuMob;
