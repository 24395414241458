import React, { lazy } from "react";
import PropTypes from "prop-types";
import Switch from "react-router-dom/Switch";
import Route from "react-router-dom/Route";
import LazyLoad from "utils/hooks/LazyLoad";
import Redirect from "react-router-dom/Redirect";

/**
|--------------------------------------------------
| Route Config
|--------------------------------------------------
*/
const Home = lazy(() => import("containers/Home/Home"));
const LazyHome = props => <LazyLoad component={Home} {...props} />;

const Shop = lazy(() => import("containers/Shop/ShopContainer"));
const LazyShop = props => <LazyLoad component={Shop} {...props} />;

const Product = lazy(() => import("containers/Product/ProductContainer"));
const LazyProduct = props => <LazyLoad component={Product} {...props} />;

const About = lazy(() => import("containers/About/AboutContainer"));
const LazyAbout = props => <LazyLoad component={About} {...props} />;

const Contact = lazy(() => import("containers/Contact/ContactContainer"));
const LazyContact = props => <LazyLoad component={Contact} {...props} />;

const Terms = lazy(() => import("containers/Terms/TermsContainer"));
const LazyTerms = props => <LazyLoad component={Terms} {...props} />;

const Privacy = lazy(() => import("containers/Privacy/PrivacyContainer"));
const LazyPrivacy = props => <LazyLoad component={Privacy} {...props} />;

const Activation = lazy(() =>
    import("containers/Activation/ActivationContainer")
);
const LazyActivation = props => <LazyLoad component={Activation} {...props} />;

const ResetPassword = lazy(() =>
    import("containers/ResetPassword/ResetPasswordContainer")
);
const LazyResetPassword = props => (
    <LazyLoad component={ResetPassword} {...props} />
);

const MyAccount = lazy(() => import("containers/MyAccount/MyAccountContainer"));
const LazyMyAccount = props => <LazyLoad component={MyAccount} {...props} />;

const Influencer = lazy(() =>
    import("containers/Influencer/InfluencerContainer")
);
const LazyInfluencer = props => <LazyLoad component={Influencer} {...props} />;

const Referral = lazy(() => import("containers/Referral/ReferralContainer"));
const LazyReferral = props => <LazyLoad component={Referral} {...props} />;

const Orders = lazy(() => import("containers/Orders/OrdersContainer"));
const LazyOrders = props => <LazyLoad component={Orders} {...props} />;

const Order = lazy(() => import("containers/Order/OrderContainer"));
const LazyOrder = props => <LazyLoad component={Order} {...props} />;

const Checkout = lazy(() => import("containers/Checkout/CheckoutContainer"));
const LazyCheckout = props => <LazyLoad component={Checkout} {...props} />;

const NotFoundPage = lazy(() => import("containers/NotFoundPage/NotFoundPage"));
const LazyNotFoundPage = props => (
    <LazyLoad component={NotFoundPage} {...props} />
);

/**
|--------------------------------------------------
| Routing
|--------------------------------------------------
*/
export default function getRoutes(dispatch, location, isAuthed) {
    return (
        <Switch>
            <Route path="/" exact={true} component={LazyHome} />
            <Route path="/shop" exact={true} component={LazyShop} />
            <Route path="/shop/category/:id" component={LazyShop} />
            <Route path="/shop/product/:id" component={LazyProduct} />
            <Route path="/about" component={LazyAbout} />
            <Route path="/contact" component={LazyContact} />
            <Route path="/terms" component={LazyTerms} />
            <Route path="/privacy" component={LazyPrivacy} />

            <Route path="/activation" component={LazyActivation} />
            <Route path="/reset-password" component={LazyResetPassword} />
            <Route
                exact={true}
                path="/referr/:code"
                component={LazyReferral}
                dispatch={dispatch}
            />
            <PrivateRoute
                exact={true}
                path="/myaccount"
                component={LazyMyAccount}
                dispatch={dispatch}
                isAuthed={isAuthed}
            />
            <PrivateRoute
                exact={true}
                path="/influencer"
                component={LazyInfluencer}
                dispatch={dispatch}
                isAuthed={isAuthed}
            />
            <PrivateRoute
                exact={true}
                path="/orders"
                component={LazyOrders}
                dispatch={dispatch}
                isAuthed={isAuthed}
            />

            <PrivateRoute
                exact={true}
                path="/checkout"
                component={LazyCheckout}
                dispatch={dispatch}
                isAuthed={isAuthed}
            />

            <PrivateRoute
                exact={true}
                path="/orders/:id"
                component={LazyOrder}
                dispatch={dispatch}
                isAuthed={isAuthed}
            />

            <Route component={LazyNotFoundPage} />
        </Switch>
    );
}

/**
|--------------------------------------------------
| Private Route Function
|--------------------------------------------------
*/
function PrivateRoute({ component: Component, isAuthed, dispatch, ...rest }) {
    return (
        <Route
            {...rest}
            render={routeProps =>
                isAuthed ? (
                    <Component {...routeProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: { from: routeProps.location }
                        }}
                    />
                )
            }
        />
    );
}
PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
    isAuthed: PropTypes.bool.isRequired
};
