import PropTypes from "prop-types";
import React from "react";
import Spinner from "./Spinner";
import "./processing.scss";

const Processing = React.memo(props => {
    const { isProcessing, text } = props;
    if (!isProcessing) {
        return null;
    }

    return (
        <div className="processing">
            <div className="processing__overlay" />
            <div className="processing__wrapper">
                <Spinner color="#2C3E50" />
                {text ? <p>{text}</p> : null}
            </div>
        </div>
    );
});

Processing.propTypes = {
    isProcessing: PropTypes.bool.isRequired,
    text: PropTypes.string.isRequired
};
export default Processing;
