/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form } from "react-final-form";
import { Field } from "react-final-form-html5-validation";
import createDecorator from "final-form-focus";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import Container from "reactstrap/lib/Container";
import cn from "classnames";
import { Icon } from "@logickit/logickit-decore/";
import Processing from "components/Spinner/Processing";
import { SimpleButton, Input, Select } from "components/ui";
import FormBG from "images/login_background.png";
import { apiUrl, apiUrlGoogle, storeName, breakPoints } from "config/constants";
import MediaQuery from "react-responsive";

import RegisterForm from "./RegisterForm";
import StyledLogin from "./Login.styled";
import LoginForm from "./LoginForm";
import "./login.scss";

const focusOnErrors = createDecorator();
// eslint-disable-next-line react/prop-types
const adapt = Component => ({ input, meta: { valid }, ...rest }) => (
    <Component {...input} {...rest} />
);
const AdaptedInput = adapt(Input);
const AdaptedSelect = adapt(Select);
const Login = React.memo(props => {
    const [currentTab, setCurrentTab] = useState("login");
    const {
        handleSignIn,
        referralProgram,
        form = "register",
        handleSignUp,
        isAuthenticating,
        handleShowResetPassword
    } = props;
    useEffect(() => {
        setCurrentTab(form);
    }, [form]);

    const onTabClick = type => {
        setCurrentTab(type);
    };

    return (
        <StyledLogin>
            <Row style={{ width: "100%", height: "100%" }}>
                <MediaQuery minWidth={breakPoints.large}>
                    <Col lg="5" style={{ paddingLeft: "0px" }}>
                        <div
                            className="login__modal-background"
                            style={{ backgroundImage: `url(${FormBG})` }}
                        />
                    </Col>
                </MediaQuery>
                <Col lg="7">
                    <Container fluid>
                        <div className="login__modal-information">
                            <div className="login__modal-information--header">
                                <SimpleButton
                                    className={cn(
                                        "login__modal-information--header_btn",
                                        {
                                            "is-active": currentTab === "login"
                                        }
                                    )}
                                    color="link"
                                    onClick={() => onTabClick("login")}
                                >
                                    Log In
                                </SimpleButton>
                                <SimpleButton
                                    className={cn(
                                        "login__modal-information--header_btn",
                                        {
                                            "is-active":
                                                currentTab === "register"
                                        }
                                    )}
                                    color="link"
                                    onClick={() => onTabClick("register")}
                                >
                                    Register
                                </SimpleButton>
                            </div>

                            <div className="login__modal-information--fields">
                                <div
                                    className="login__modal-content is-active"
                                    id={currentTab}
                                >
                                    <Form
                                        decorators={[focusOnErrors]}
                                        render={({ handleSubmit }) => (
                                            <form
                                                className="login__modal-content--form"
                                                onSubmit={handleSubmit}
                                            >
                                                {currentTab === "login" ? (
                                                    <LoginForm
                                                        AdaptedInput={
                                                            AdaptedInput
                                                        }
                                                        AdaptedSelect={
                                                            AdaptedSelect
                                                        }
                                                        Field={Field}
                                                        handleShowResetPassword={
                                                            handleShowResetPassword
                                                        }
                                                    />
                                                ) : (
                                                    <RegisterForm
                                                        referralProgram
                                                        AdaptedInput={
                                                            AdaptedInput
                                                        }
                                                        AdaptedSelect={
                                                            AdaptedSelect
                                                        }
                                                        Field={Field}
                                                    />
                                                )}
                                            </form>
                                        )}
                                        onSubmit={
                                            currentTab === "login"
                                                ? handleSignIn
                                                : handleSignUp
                                        }
                                    />
                                </div>
                            </div>

                            <div className="login__modal-oauth">
                                {/* <span className="login__modal-oauth--divider">
                                    — or —
                                </span> */}
                                <a
                                    className="login__modal-oauth--btn --google"
                                    href={`${apiUrlGoogle}/auth/google_oauth2?X-Logickit-Store=${storeName}`}
                                >
                                    <Icon src="data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3542 7.15106C13.3542 10.836 10.8308 13.4584 7.10421 13.4584C3.53129 13.4584 0.645874 10.5729 0.645874 7.00002C0.645874 3.4271 3.53129 0.541687 7.10421 0.541687C8.84379 0.541687 10.3073 1.17971 11.4349 2.23179L9.67712 3.92189C7.37764 1.70315 3.1016 3.36981 3.1016 7.00002C3.1016 9.25262 4.90108 11.0781 7.10421 11.0781C9.6615 11.0781 10.6198 9.24481 10.7709 8.29429H7.10421V6.07294H13.2526C13.3125 6.40367 13.3542 6.72137 13.3542 7.15106Z' fill='currentColor'/%3E%3C/svg%3E%0A" />
                                    <span>Continue with Google</span>
                                </a>
                                {/* <a
                                    href={`${apiUrl}/auth/facebook?X-Logickit-Store=${storeName}`}
                                    className="login__modal-oauth--btn --facebook"
                                >
                                    <Icon src="data:image/svg+xml,%3Csvg width='8' height='14' viewBox='0 0 8 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5599 13.6666V7.7031H0.5625V5.33331H2.5599V3.46613C2.5599 1.43748 3.79948 0.333313 5.60938 0.333313C6.47656 0.333313 7.22135 0.398417 7.4375 0.427063V2.54685H6.18229C5.19792 2.54685 5.00781 3.0156 5.00781 3.7005V5.33331H7.22917L6.92448 7.7031H5.00781V13.6666' fill='currentColor'/%3E%3C/svg%3E%0A" />
                                    <span>Continue with Facebook</span>
                                </a> */}
                            </div>
                        </div>
                    </Container>
                </Col>
            </Row>

            <Processing isProcessing={isAuthenticating} />
        </StyledLogin>
    );
});
Login.propTypes = {
    handleSignIn: PropTypes.func.isRequired,
    form: PropTypes.string.isRequired,
    handleSignUp: PropTypes.func.isRequired,
    isAuthenticating: PropTypes.bool.isRequired,
    handleShowResetPassword: PropTypes.func.isRequired,
    referralProgram: PropTypes.bool.isRequired
};
export default Login;
