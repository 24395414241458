import * as Sentry from "@sentry/browser";
import * as log from "loglevel";

export const error = (err = {}, scopeInformation = {}) => {
    Sentry.configureScope(scope => {
        if (scopeInformation.user) {
            scope.setUser(scopeInformation.user);
        }

        if (scopeInformation.tags) {
            Object.entries(scopeInformation.tags).forEach(([key, value]) => {
                scope.setTag(key, value);
            });
        }

        if (scopeInformation.extra) {
            Object.entries(scopeInformation.extra).forEach(([key, value]) => {
                scope.setExtra(key, value);
            });
        }
    });

    Sentry.captureException(err);

    log.error(err);
};

export const warn = (...args) => {
    log.warn(...args);
};

export const info = (...args) => {
    log.info(...args);
};

export const debug = (...args) => {
    log.debug(...args);
};

export const trace = (...args) => {
    log.trace(...args);
};
