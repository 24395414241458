const SET_ELECTRONIC_PAYMENTS = "SET_ELECTRONIC_PAYMENTS";

export const setElectronicPayments = data => ({
    type: SET_ELECTRONIC_PAYMENTS,
    data
});

const initialState = {
    electronicPayments: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ELECTRONIC_PAYMENTS:
            return {
                ...state,
                electronicPayments: action.data
            };

        default:
            return state;
    }
};
