import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { closeModal, enqueueModal } from "store/modules/modal";
import { handleLogin, handleRegister } from "store/modules/authentication";
import ResetPasswordModal from "components/Modal/ResetPasswordModal";
import Login from "./Login";

const LoginContainer = React.memo(props => {
    const { isAuthenticating, dispatch, form, referralProgram } = props;

    const handleCloseModal = () => {
        dispatch(closeModal());
    };
    const handleShowResetPassword = async () => {
        await dispatch(
            enqueueModal({
                title: "",
                classes: "reset__modal-container",
                data: <ResetPasswordModal handleCloseModal={handleCloseModal} />
            })
        );
    };
    const handleSignIn = async values => {
        const formData = new FormData();
        formData.append("app_user[email]", values.email);
        formData.append("app_user[password]", values.password);

        dispatch(handleLogin(formData));
        return true;
    };

    const handleSignUp = async values => {
        const formData = new FormData();
        formData.append("app_user[first_name]", values.firstname);
        formData.append("app_user[last_name]", values.lastname);
        // formData.append("app_user[user_type]", values.user_type);
        formData.append("app_user[email]", values.email);
        formData.append("app_user[opt_in_message]", true);
        formData.append("app_user[password]", values.password);
        formData.append(
            "app_user[password_confirmation]",
            values.password_confirmation
        );
        if (values && values.referralCode) {
            formData.append("app_user[referral_code]", values.referralCode);
        }
        return dispatch(handleRegister(formData));
    };

    return (
        <Login
            form={form}
            handleCloseModal={handleCloseModal}
            handleShowResetPassword={handleShowResetPassword}
            handleSignIn={handleSignIn}
            handleSignUp={handleSignUp}
            isAuthenticating={isAuthenticating}
            referralProgram={referralProgram}
        />
    );
});

LoginContainer.propTypes = {
    isAuthenticating: PropTypes.bool.isRequired,
    form: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    referralProgram: PropTypes.bool.isRequired
};
function mapStateToProps({ authentication, general }) {
    return {
        isAuthenticating: authentication.isAuthenticating,
        isAuthed: authentication.isAuthed,
        profileData: authentication.profileData,
        referralProgram:
            general &&
            general.general &&
            general.general.features &&
            general.general.features.referral_program
    };
}

export default connect(mapStateToProps)(LoginContainer);
