import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";
import { Button, SimpleButton } from "components/ui";
import HeaderUser from "./HeaderUser";

const HeaderAccount = React.memo(props => {
    const {
        isAuthed,
        openLoginModal,
        cartItems,
        toggleMenu,
        setSearchVisible
    } = props;
    return (
        <div className="header__account">
            <SimpleButton
                className="header__account-search"
                color="link"
                onClick={() => setSearchVisible(true)}
            />
            {!isAuthed ? (
                <>
                    <Button
                        outline
                        className="header__account-btn button"
                        style={{ marginRight: 16 }}
                        onClick={() => openLoginModal("register")}
                    >
                        Register
                    </Button>
                    <Button
                        className="header__account-btn"
                        onClick={() => openLoginModal("login")}
                    >
                        Log In
                    </Button>
                </>
            ) : (
                <>
                    <HeaderUser />
                    <button
                        className={cn("header__account-cart", {
                            "-active": cartItems > 0,
                            "is-logged": isAuthed
                        })}
                        type="button"
                        onClick={toggleMenu}
                    >
                        <span
                            className={cn("header__account-cart--badge", {
                                "d-none": cartItems === 0
                            })}
                        >
                            {cartItems}
                        </span>
                    </button>
                </>
            )}
        </div>
    );
});

HeaderAccount.propTypes = {
    isAuthed: PropTypes.bool.isRequired,
    openLoginModal: PropTypes.func.isRequired,
    cartItems: PropTypes.number.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    setSearchVisible: PropTypes.func.isRequired
};

export default HeaderAccount;
