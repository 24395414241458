import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import FormGroup from "reactstrap/lib/FormGroup";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import Processing from "components/Spinner/Processing";
import Container from "reactstrap/lib/Container";
import LocationSearchInput from "components/LocationSearchInput/LocationSearchInput";
import {
    updateSelectedLocation,
    checkSelectedLocationStatus
} from "api/location";
import * as toast from "utils/toast";
import { Button } from "components/ui";
import "./DeliveryAddressModal.scss";

const NO_DELIVERY = "not validate!";
const APPROVED_LOCATION = "validate!";
let zipCode = null;

const DeliveryAddressModal = React.memo(props => {
    const [initialized, setInitialized] = useState(false);
    const [selectLoading, setSelectLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentAddress, setCurrentAddress] = useState("");
    const [addressSubmitted, setAddressSubmitted] = useState(false);
    const [validateLocation, setValidateLocation] = useState({
        DeliveryLocationValidator: false,
        DeliveryLocationValidatorMessage: ""
    });
    const [deliveryLocation, setDeliveryLocation] = useState({
        address: "",
        lat: "",
        lon: "",
        zip: "",
        is_selected: true
    });
    const { DeliveryLocationValidator } = validateLocation;

    const { updateLocation, currentLocation, notInRange } = props;
    useEffect(() => {
        if (!initialized) {
            const validator = { ...setValidateLocation };
            if (notInRange) {
                validator.DeliveryLocationValidator = false;
                validator.DeliveryLocationValidatorMessage = NO_DELIVERY;
            } else {
                validator.DeliveryLocationValidator = true;
                validator.DeliveryLocationValidatorMessage = APPROVED_LOCATION;
            }
            setCurrentAddress(currentLocation);
            setValidateLocation(validator);
            setInitialized(true);
        }
    }, [currentLocation]);
    const onSubmit = () => {
        if (addressSubmitted) {
            setLoading(true);
            updateSelectedLocation(deliveryLocation)
                .then(data => {
                    toast.success({
                        text: "Your location was submitted successfully."
                    });
                    setLoading(false);
                    updateLocation(data);
                })
                .catch(() => {
                    setLoading(false);
                });
        } else {
            toast.error({
                text: "Please enter and choose your location."
            });
        }
    };
    const handleOnChange = address => {
        const data = { ...deliveryLocation };
        data.address = address;
        setDeliveryLocation(data);
    };

    const checkLocation = data => {
        const validator = { ...setValidateLocation };
        const deliveryData = { ...deliveryLocation };
        deliveryData.address = data.address;
        deliveryData.lat = data.lat;
        deliveryData.lon = data.lon;
        deliveryData.zip = zipCode;
        setDeliveryLocation(deliveryData);
        checkSelectedLocationStatus(data)
            .then(response => {
                if (
                    response.code &&
                    response.code === "DELIVERY_NOT_IN_RANGE"
                ) {
                    validator.DeliveryLocationValidator = false;
                    validator.DeliveryLocationValidatorMessage = NO_DELIVERY;
                } else if (response.status && response.status === "OK") {
                    validator.DeliveryLocationValidator = true;
                    validator.DeliveryLocationValidatorMessage = APPROVED_LOCATION;
                }
                setAddressSubmitted(true);
                setSelectLoading(false);
                setCurrentAddress(data.address);
                setValidateLocation(validator);
            })
            .catch(() => {
                deliveryData.deliveryLocation = data.address;
                validator.DeliveryLocationValidator = false;
                validator.DeliveryLocationValidatorMessage = NO_DELIVERY;
                setAddressSubmitted(false);
                setSelectLoading(false);
                setValidateLocation(validator);
                setDeliveryLocation(deliveryData);
            });
    };
    const getResultData = (Data, name) => {
        const Result = Data.address_components.filter(
            item => item.types[0] === name
        );
        if (Result.length > 0) {
            return Result[0].long_name;
        }
        return null;
    };
    const getZipCode = async result => {
        zipCode = getResultData(result, "postal_code");
        return result;
    };
    const handleOnSelect = address => {
        const data = { ...deliveryLocation };
        setSelectLoading(true);
        geocodeByAddress(address)
            .then(results => getZipCode(results[0]))
            .then(results => getLatLng(results))
            .then(latLng => {
                data.address = address;
                data.lat = latLng.lat;
                data.lon = latLng.lng;
                data.zip = zipCode;
                setDeliveryLocation(data);
                checkLocation(data);
            });
    };
    return (
        <Container>
            <Row>
                <Col lg={12}>
                    <p>
                        your current delivery location is:{" "}
                        <strong>{currentAddress}</strong>
                        <br />
                    </p>
                </Col>
                <Col lg={12}>
                    <FormGroup>
                        <LocationSearchInput
                            address={deliveryLocation.address}
                            handleChange={handleOnChange}
                            handleSelect={handleOnSelect}
                        />
                    </FormGroup>
                    {!DeliveryLocationValidator && (
                        <p>
                            <span className="delivery_address_modal-error-dialog">
                                We do not deliver to this location. Please
                                choose another location
                            </span>
                        </p>
                    )}
                    <FormGroup>
                        <Button
                            className="delivery_address_modal-btn"
                            disabled={!addressSubmitted}
                            loading={loading}
                            onClick={onSubmit}
                        >
                            Submit
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
            <Processing isProcessing={selectLoading} />
        </Container>
    );
});

DeliveryAddressModal.propTypes = {
    updateLocation: PropTypes.func.isRequired,
    currentLocation: PropTypes.func.isRequired,
    notInRange: PropTypes.bool.isRequired
};
export default DeliveryAddressModal;
