import request from "utils/request";

/**
 * Register user
 * @param {Object} data
 * @returns {Promise}
 */
export function register(data) {
    return request("register", data, "POST");
}

/**
 * Login user
 * @param {Object} data
 * @returns {Promise}
 */
export function login(data) {
    return request("login", data, "POST");
}

/**
 * Get users information
 * @returns {Promise}
 */
export function getUser() {
    return request("user_info", null, "GET");
}

/**
 * Sign-out user
 */
export function signOut() {
    return request("sign_out", null, "DELETE");
}
