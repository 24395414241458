import React from "react";
import {
    IoIosInformationCircle,
    IoIosClose,
    IoIosCheckmarkCircle,
    IoIosWarning
} from "react-icons/io";
import { MdError } from "react-icons/md";
import PropTypes from "prop-types";

import "./toast.scss";

export const SuccessToast = React.memo(({ closeToast, text }) => (
    <div className="toast-container -success">
        <div className="toast-container-toast">
            <IoIosCheckmarkCircle
                className="toast-container-toast--icon -success"
                size="50"
            />
            <div className="toast-container-toast--text">
                <h3 className="toast-container-toast--title">Success</h3>
                <p className="toast-container-toast--paragraph">{text}</p>
            </div>
        </div>
        <IoIosClose size="25" onClick={closeToast} />
    </div>
));

SuccessToast.propTypes = {
    closeToast: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

export const ErrorToast = React.memo(({ closeToast, text }) => (
    <div className="toast-container -error">
        <div className="toast-container-toast">
            <MdError className="toast-container-toast--icon -error" size="50" />
            <div className="toast-container-toast--text">
                <h3 className="toast-container-toast--title">Error</h3>
                <p className="toast-container-toast--paragraph">{text}</p>
            </div>
        </div>
        <IoIosClose size="25" onClick={closeToast} />
    </div>
));

ErrorToast.propTypes = {
    closeToast: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

export const WarningToast = React.memo(({ closeToast, text }) => (
    <div className="toast-container -warning">
        <div className="toast-container-toast">
            <IoIosWarning
                className="toast-container-toast--icon -warning"
                size="50"
            />
            <div className="toast-container-toast--text">
                <h3 className="toast-container-toast--title">Warning</h3>
                <p className="toast-container-toast--paragraph">{text}</p>
            </div>
        </div>
        <IoIosClose size="25" onClick={closeToast} />
    </div>
));

WarningToast.propTypes = {
    closeToast: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};

export const InfoToast = React.memo(({ closeToast, text }) => (
    <div className="toast-container -info">
        <div className="toast-container-toast">
            <IoIosInformationCircle
                className="toast-container-toast--icon -info"
                size="50"
            />
            <div className="toast-container-toast--text">
                <h3 className="toast-container-toast--title">Info</h3>
                <p className="toast-container-toast--paragraph">{text}</p>
            </div>
        </div>
        <IoIosClose size="25" onClick={closeToast} />
    </div>
));

InfoToast.propTypes = {
    closeToast: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
};
