import PropTypes from "prop-types";
import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import Spinner from "components/Spinner/Spinner";
import RequestHandler from "utils/requestHandler";
import { prepareApp } from "store/modules/app";

class AppLoadingContainer extends Component {
    static propTypes = {
        dispatch: PropTypes.func.isRequired,
        children: PropTypes.element.isRequired,
        appIsReady: PropTypes.bool.isRequired
    };

    constructor(props) {
        super(props);
        RequestHandler();
    }

    async componentDidMount() {
        const { appIsReady, dispatch } = this.props;

        if (!appIsReady) {
            await dispatch(prepareApp());
        }
    }

    render() {
        const { appIsReady, children } = this.props;

        if (appIsReady) {
            return children;
        }

        return (
            <main>
                <div className="loading-container text-center">
                    <Spinner />
                </div>
            </main>
        );
    }
}

const mapStateToProps = ({ app }) => ({
    appIsReady: app.appIsReady,
    appIsLoading: app.appIsLoading
});

export default connect(mapStateToProps)(AppLoadingContainer);
