import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "reactstrap/lib/Container";
import { Link } from "react-router-dom";
import Navbar from "reactstrap/lib/Navbar";
import NavbarBrand from "reactstrap/lib/NavbarBrand";
import cn from "classnames";
import { Img, Button } from "components/ui";
import _ from "lodash";
import getAllCategories from "api/categories";
import { setAllCategories } from "store/modules/categories";
import { slide as Menu } from "react-burger-menu";
import HeaderSearchForm from "./SearchForm/HeaderSearchForm";
import Burger from "./BurgerMenu/Burger";
import MenuMob from "./BurgerMenu/Menu";

const MobileHeader = React.memo(props => {
    const [openMenu, setOpenMenu] = useState(false);
    const [isPage, setIsPage] = useState("/");
    const menuId = "main-menu";
    const {
        isAuthed,
        location: { pathname: currentPath },
        openLoginModal,
        toggleMenu,
        searchVisible,
        cartItems,
        setSearchVisible,
        logo,
        userInfo,
        dispatch,
        categories,
        searchCategory
    } = props;

    const handleGetCategories = () => {
        getAllCategories().then(data => {
            dispatch(setAllCategories(data));
        });
    };
    useEffect(() => {
        setIsPage(currentPath);
        setOpenMenu(false);
        if (_.isEmpty(categories)) {
            handleGetCategories();
        }
    }, [currentPath]);

    const onSearchButtonClick = () => {
        setSearchVisible(true);
        setOpenMenu(false);
    };
    const closeMenu = () => {
        setOpenMenu(false);
    };
    return (
        <>
            <div className="header-mobile">
                <Container fluid>
                    <Navbar light color="faded">
                        <Burger
                            aria-controls={menuId}
                            open={openMenu}
                            setOpen={setOpenMenu}
                        />
                        <NavbarBrand className="mr-auto" href="/">
                            <Link className="header__logo" to="/">
                                <Img alt="" lazy={false} src={logo} />
                            </Link>
                        </NavbarBrand>
                        {searchVisible && (
                            <HeaderSearchForm
                                setSearchVisible={setSearchVisible}
                            />
                        )}
                        <button
                            className="header__account-search"
                            type="button"
                            onClick={() => onSearchButtonClick()}
                        />
                        {isAuthed && (
                            <>
                                {/* <HeaderUser /> */}
                                <button
                                    className={cn("header__account-cart", {
                                        "-active": cartItems > 0,
                                        "is-logged": isAuthed
                                    })}
                                    type="button"
                                    onClick={toggleMenu}
                                >
                                    <span
                                        className={cn(
                                            "header__account-cart--badge",
                                            {
                                                "d-none": cartItems === 0
                                            }
                                        )}
                                    >
                                        {cartItems}
                                    </span>
                                </button>
                            </>
                        )}
                        {!isAuthed && (
                            <div className="header__btn">
                                <Button
                                    outline
                                    className="header__account-btn button mobile"
                                    style={{ marginRight: 16 }}
                                    onClick={() => openLoginModal("register")}
                                >
                                    Register
                                </Button>
                                <Button
                                    className="header__account-btn mobile"
                                    onClick={() => openLoginModal("login")}
                                >
                                    Log In
                                </Button>
                            </div>
                        )}
                    </Navbar>
                </Container>
            </div>
            <Menu
                bodyClassName="noscroll"
                className="my-menu"
                isOpen={openMenu}
                menuClassName="header__mobile-menu"
                overlayClassName="header__cart-menu--overlay"
                width="100%"
            >
                <MenuMob
                    aria-controls={menuId}
                    categories={categories}
                    closeMenu={closeMenu}
                    dispatch={dispatch}
                    id={menuId}
                    isAuthed={isAuthed}
                    isPage={isPage}
                    logo={logo}
                    open={openMenu}
                    openLoginModal={openLoginModal}
                    searchCategory={searchCategory}
                    setOpen={setOpenMenu}
                    userInfo={userInfo}
                />
            </Menu>
        </>
    );
});

MobileHeader.propTypes = {
    isAuthed: PropTypes.bool.isRequired,
    openLoginModal: PropTypes.func.isRequired,
    toggleMenu: PropTypes.func.isRequired,
    location: PropTypes.objectOf.isRequired,
    cartItems: PropTypes.number.isRequired,
    searchVisible: PropTypes.bool.isRequired,
    logo: PropTypes.string.isRequired,
    searchCategory: PropTypes.string.isRequired,
    setSearchVisible: PropTypes.func.isRequired,
    userInfo: PropTypes.objectOf.isRequired,
    categories: PropTypes.arrayOf.isRequired,
    dispatch: PropTypes.func.isRequired
};

export default MobileHeader;
