/* eslint-disable no-unused-vars */
import React from "react";
import { getUser } from "api/auth";
import _ from "lodash";
import { getAllBrands } from "api/products";
import { viewCart } from "api/cart";
import getGeneralInfo from "api/general";
import AgeModal from "components/AgeModal/AgeModal";
import ReferrerProgramModal from "components/ReferrerProgramModal/ReferrerProgramModal";
import OptInMessageModal from "components/OptInMessageModal/OptInMessageModal";
import TOUModal from "components/Modal/TOUModal";
import { loadAuthToken, removeAuthToken } from "utils/helpers";
import { setBrands } from "./products";
import { setGeneralInfo } from "./general";
import { enqueueModal } from "./modal";
import { setUserInfo } from "./userInfo";
import { authenticated, setCartItem } from "./authentication";

const AGE_CONFIRMED = "AGE_CONFIRMED";

const ageConfirm = localStorage.getItem(AGE_CONFIRMED);
const firstTimereFerral = sessionStorage.getItem("firstTimereFerral");

let referrerProgramInfo = false;

const authToken = loadAuthToken();

const APP_IS_LOADING = "APP_IS_LOADING";
const APP_IS_READY = "APP_IS_READY";

export function prepareApp() {
    return async dispatch => {
        dispatch({ type: APP_IS_LOADING, status: true });
        try {
            if (authToken && authToken !== null) {
                try {
                    const user = await getUser();
                    const userId = user.id;
                    const REFERRER_PROGRAM_INFO = "REFERRER_PROGRAM_INFO";
                    referrerProgramInfo = localStorage.getItem(
                        REFERRER_PROGRAM_INFO + userId
                    );
                    const { cart_items: cartItems } = await viewCart();
                    const cartLength = cartItems.reduce(
                        (acc, val) => acc + val.quantity,
                        0
                    );

                    dispatch(
                        setUserInfo({
                            user
                        })
                    );

                    const {
                        id,
                        first_name: firstName,
                        last_name: lastName,
                        tou_agreed_at: touAgreedAt,
                        email,
                        zip
                    } = user;

                    if (_.isEmpty(touAgreedAt)) {
                        dispatch(
                            enqueueModal({
                                title: "Term Of Use",
                                classes: "tou_modal-container",
                                data: <TOUModal dispatch={dispatch} />
                            })
                        );
                    }
                    if (
                        user &&
                        user.opt_in_message === null &&
                        window.location.pathname === "/"
                    ) {
                        dispatch(
                            enqueueModal({
                                title: "",
                                classes: "opt_message__modal-container",
                                data: <OptInMessageModal dispatch={dispatch} />
                            })
                        );
                    }
                    dispatch(authenticated(user));
                    dispatch(setCartItem(cartLength));
                } catch (e) {
                    removeAuthToken();
                }
            }

            const [brands, generalInfo] = await Promise.all([
                getAllBrands(),
                getGeneralInfo()
            ]);
            dispatch(setBrands(brands));
            dispatch(setGeneralInfo(generalInfo));

            if (!ageConfirm) {
                dispatch(
                    enqueueModal({
                        title: "",
                        classes: "age__modal-container",
                        data: <AgeModal dispatch={dispatch} />
                    })
                );
            }
            if (
                generalInfo &&
                generalInfo.features &&
                generalInfo.features.referral_program &&
                !referrerProgramInfo &&
                authToken &&
                !firstTimereFerral &&
                window.location.pathname === "/"
            ) {
                dispatch(
                    enqueueModal({
                        title: "",
                        classes: "referrer__modal-container",
                        data: <ReferrerProgramModal dispatch={dispatch} />
                    })
                );
            }
        } finally {
            dispatch({ type: APP_IS_LOADING, status: false });
            dispatch({ type: APP_IS_READY, status: true });
        }
    };
}
const initialState = {
    appIsLoading: false,
    appIsReady: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case APP_IS_LOADING:
            return {
                ...state,
                appIsLoading: action.status
            };
        case APP_IS_READY:
            return {
                ...state,
                appIsReady: action.status
            };

        default:
            return state;
    }
};
