import { register, login, signOut } from "api/auth";
import { removeAuthToken, LoadLocationOnCookie } from "utils/helpers";
import { updateSelectedLocation } from "api/location";
import * as toast from "utils/toast";

const SET_AUTH_STATUS = "SET_AUTH_STATUS";
const IS_AUTHENTICATING = "IS_AUTHENTICATING";
const IS_AUTHENTICATED = "IS_AUTHENTICATED";
const LOGOUT = "LOGOUT";
const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";
const REGISTERED = "REGISTERED";
const SET_HAS_ZIP = "SET_HAS_ZIP";
const SET_CART_ITEM = "SET_CART_ITEM";
const SET_CART_AMOUNT = "SET_CART_AMOUNT";
const TOU_CONFIRMED = "TOU_CONFIRMED";

export const setAuthStatus = data => ({
    type: SET_AUTH_STATUS,
    data
});

export const authenticated = data => ({
    type: IS_AUTHENTICATED,
    data
});

export const authenticating = () => ({
    type: IS_AUTHENTICATING
});

export const registered = () => ({
    type: REGISTERED
});

export const updateProfileData = data => ({
    type: UPDATE_PROFILE_DATA,
    data
});

export const setHasZip = data => ({
    type: SET_HAS_ZIP,
    data
});

export const setCartItem = data => ({
    type: SET_CART_ITEM,
    data
});

export const setCartAmount = data => ({
    type: SET_CART_AMOUNT,
    data
});

export function handleRegister(formData) {
    return async dispatch => {
        dispatch(authenticating());

        try {
            await register(formData);
            localStorage.setItem(TOU_CONFIRMED, "true");
            toast.success({
                text: "Please check your email to verify account."
            });
            localStorage.removeItem("referralCode");
        } finally {
            dispatch(authenticating());
        }
    };
}

export function handleLogin(formData) {
    return dispatch => {
        dispatch(authenticating());
        login(formData)
            .then(async () => {
                if (LoadLocationOnCookie() !== undefined) {
                    await updateSelectedLocation(
                        JSON.parse(LoadLocationOnCookie())
                    );
                    window.location.reload(true);
                } else {
                    window.location.reload(true);
                }
                dispatch(authenticating());
            })
            .catch(err => {
                dispatch(authenticating());
                const {
                    response: { status, data }
                } = err;
                if (status === 401) {
                    toast.error({ text: data.error.message });
                }
            });
    };
}

export function handleLogout() {
    return async dispatch => {
        await signOut().then(() => {
            removeAuthToken();
            dispatch({ type: LOGOUT });
            window.location.replace("/");
        });
    };
}

const initialState = {
    isAuthed: false,
    isAuthenticating: false,
    authFailed: "",
    profileData: {},
    hasZip: {
        status: null,
        messsage: ""
    },
    cartItemsLength: 0,
    totalCartAmount: 0
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_STATUS:
            return {
                ...state,
                isAuthed: action.data
            };
        case UPDATE_PROFILE_DATA:
            return {
                ...state,
                profileData: {
                    ...state.profileData,
                    ...action.data
                }
            };
        case IS_AUTHENTICATED:
            return {
                ...state,
                isAuthed: true,
                isAuthenticating: false,
                profileData: {
                    ...state.profileData,
                    ...action.data
                }
            };
        case REGISTERED:
            return {
                ...state,
                isAuthenticating: false
            };
        case IS_AUTHENTICATING:
            return {
                ...state,
                isAuthenticating: !state.isAuthenticating
            };
        case LOGOUT:
            return {
                ...state,
                isAuthed: false,
                profileData: null
            };
        case SET_HAS_ZIP:
            return {
                ...state,
                hasZip: action.data
            };
        case SET_CART_ITEM:
            return {
                ...state,
                cartItemsLength: action.data
            };
        case SET_CART_AMOUNT:
            return {
                ...state,
                totalCartAmount: action.data
            };

        default:
            return state;
    }
};
