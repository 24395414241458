import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Image from "@logickit/logickit-decore/build/lib/components/Image";
import { lazyLoadingOffset } from "config/constants";

const NoImage = styled.div`
    background: "#e0e0e0";
    width: auto;
    height: 100%;
`;
const LKStyledImage = styled(Image)`
    border-radius: ${props => props.radius || "0"};
    object-fit: contain
        ${props => {
            return (
                props.fluid &&
                css`
                    width: 100%;
                    height: auto;
                `
            );
        }};
`;
const Img = React.memo(props => {
    const { src, resize, lazy } = props;
    return src !== "" && src !== undefined ? (
        <LKStyledImage
            lazy={lazy}
            offset={lazyLoadingOffset}
            {...props}
            resize={resize}
        />
    ) : (
        <NoImage />
    );
});

Img.propTypes = {
    radius: PropTypes.string,
    fluid: PropTypes.bool,
    resize: PropTypes.bool,
    lazy: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired
};
Img.defaultProps = {
    className: "",
    fluid: false,
    height: "auto",
    lazy: true,
    radius: "0",
    resize: true,
    width: "auto"
};
export default Img;
