const SET_GENERAL_INFO = "SET_GENERAL_INFO";

export const setGeneralInfo = data => ({
    type: SET_GENERAL_INFO,
    data
});

const initialState = {
    general: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_GENERAL_INFO:
            return {
                ...state,
                general: action.data
            };

        default:
            return state;
    }
};
