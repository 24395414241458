const SET_ALL_CATEGORIES = "SET_ALL_CATEGORIES";

export const setAllCategories = data => ({
    type: SET_ALL_CATEGORIES,
    data
});

const initialState = {
    categories: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_CATEGORIES:
            return {
                ...state,
                categories: action.data
            };

        default:
            return state;
    }
};
