/* eslint-disable no-param-reassign */
import axios from "axios";
import _ from "lodash";
import { apiUrl, storeName } from "config/constants";
// eslint-disable-next-line no-unused-vars
import { loadAuthToken, saveAuthToken, LoadGUIDCookie } from "./helpers";
import * as toast from "./toast";
import * as log from "./log";

const ErrorHandler = error => {
    const {
        response: { status, data }
    } = error;
    if (status === 400 || status === 403) {
        if (data.code !== "ERROR_GETTING_USER_LOCATION") {
            if (_.get(data, "error.message")) {
                toast.error({ text: data.error.message });
            } else {
                toast.error({
                    text: "Something went wrong, please try again."
                });
            }
        }
        log.error(error);
    }
    return Promise.reject(error);
};
const RequestHandler = () => {
    axios.defaults.baseURL = apiUrl;

    axios.interceptors.request.use(
        config => {
            const token = loadAuthToken();
            const guid = LoadGUIDCookie();
            config.headers["X-Logickit-Store"] = storeName;
            config.withCredentials = true;
            if (guid && guid !== null) config.headers["X-Logickit-GUID"] = guid;
            if (token && token !== null)
                config.headers.Authorization = `Bearer ${token}`;
            return config;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        response => {
            const authToken = response.headers.authorization
                ? response.headers.authorization.replace(/Bearer /g, "")
                : null;
            if (authToken) {
                saveAuthToken(authToken);
            }

            return response;
        },
        error => ErrorHandler(error)
    );
};
export default RequestHandler;
