import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Container from "reactstrap/lib/Container";
import { updateUser } from "api/user";
import { closeModal } from "store/modules/modal";
import { IconClose } from "components/ui/Icon";
import "./optInMessage-modal.scss";
import { Button, Img } from "components/ui";
import * as toast from "utils/toast";

const OptInMessageModal = React.memo(props => {
    const { dispatch, general, profileData } = props;
    const handleSubmit = async (e, value) => {
        e.preventDefault();
        const data = {
            opt_in_message: value
        };
        await updateUser(data).then(response => {
            toast.success({ text: response.message });
            dispatch(closeModal());
        });
    };
    return (
        <div className="opt_message__modal">
            <Container>
                <Img alt="" src={general.logo} />
                <h2>
                    Would you like to continue receiving exclusive and limited
                    release offers via text messaging to{" "}
                    {profileData && profileData.cell_phone}?
                </h2>
                <div className="buttons">
                    <Button onClick={e => handleSubmit(e, true)}>
                        Yes, continue
                    </Button>
                    <Button onClick={e => handleSubmit(e, false)}>
                        No, turn it off
                    </Button>
                </div>
                <div className="cta" onClick={handleSubmit}>
                    <IconClose color="var(--gray6)" height="16" width="16" />
                </div>
            </Container>
        </div>
    );
});

OptInMessageModal.propTypes = {
    profileData: PropTypes.objectOf.isRequired,
    dispatch: PropTypes.func.isRequired,
    general: PropTypes.objectOf.isRequired
};
const mapStateToProps = ({ general, authentication }) => ({
    general: general.general,
    profileData: authentication.profileData
});
export default connect(mapStateToProps)(OptInMessageModal);
