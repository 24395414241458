/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import Col from "reactstrap/lib/Col";
import { searchProducts } from "api/products";
import { IconClose } from "components/ui/Icon";
import { Link } from "react-router-dom";
import SearchItem from "./SearchItem";

const HeaderSearchForm = props => {
    const [results, setResults] = useState();
    const [inputValue, setValue] = useState();
    const [showResults, setShowResults] = useState(false);
    const { dispatch } = props;
    const handleChange = async e => {
        const searchValue = e.target.value;

        setValue(searchValue);

        if (searchValue.length < 3) {
            setResults(null);
            setShowResults(false);
            return false;
        }

        try {
            const searchResult = await searchProducts(searchValue);
            setResults(searchResult.products);
            setShowResults(true);
        } catch (err) {
            return false;
        }
        return true;
    };
    const { setSearchVisible } = props;
    return (
        <>
            <Division>
                <StyledContainer>
                    <StyledRow>
                        <Col style={{ padding: "0" }} xs={12}>
                            <InputDiv hasLine>
                                <SearchInput
                                    autoFocus
                                    placeholder="Search products"
                                    type="text"
                                    onChange={handleChange}
                                />
                            </InputDiv>
                        </Col>
                    </StyledRow>
                    <ResultsDiv show={showResults}>
                        {results && results.length > 0
                            ? results.map(item => (
                                  <SearchItem key={item.id} item={item} />
                              ))
                            : "No Result Found."}
                        {results && results.length > 0 ? (
                            <Link
                                className="see-all"
                                to={`/shop?query=${inputValue}`}
                                onClick={() => setSearchVisible(false)}
                            >
                                All Results
                            </Link>
                        ) : (
                            ""
                        )}
                    </ResultsDiv>
                    <CloseButton onClick={() => setSearchVisible(false)}>
                        <IconClose
                            color="var(--gray6)"
                            height="16"
                            width="16"
                        />
                    </CloseButton>
                </StyledContainer>
            </Division>
        </>
    );
};

const Division = styled.div`
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    background: #fff;
    height: 100%;
    display: flex;

    @media (max-width: 700px) {
        z-index: 2 !important;
    }
`;
const InputDiv = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    ${props =>
        props.hasLine &&
        `
        &:before {
        content: "";
        width: 1px;
        height: 70%;
        margin: auto 20px auto 0;
        background-color: var(--gray3);
        
        @media screen and (max-width: 768px) {
            display: none;
        }
        `}
`;
const StyledRow = styled(Row)`
    height: 100%;
    position: relative;
`;
const SearchInput = styled.input`
    font-size: 18px;
    height: 100%;
    border: none;
    outline: none;
    flex: 7;
`;
const StyledContainer = styled(Container)`
    position: relative;
    padding-left: 115px !important;
    @media screen and (max-width: 700px) {
        padding-left: 15px !important;
    }
`;
const CloseButton = styled.div`
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    .icon {
        cursor: pointer;
    }
`;
const ResultsDiv = styled.div`
    width: 100%;
    display: none;
    background-color: #fff;
    padding: 10px 20px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1045;
    right: auto;
    box-shadow: 0px 1px 3px #ccc;
    display: none;
    ${props => props.show && `display:block`}
    .see-all {
        text-align: center;
        display: inherit;
        font-size: 18px;
    }
`;

HeaderSearchForm.propTypes = {
    setSearchVisible: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired
};

const mapStateToProps = ({ authentication }) => {
    return {
        locationId: authentication.locationId
    };
};

export default connect(mapStateToProps)(HeaderSearchForm);
