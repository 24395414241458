/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Input } from "@logickit/logickit-decore/";
import Label from "reactstrap/lib/Label";
import PropTypes from "prop-types";
import styled from "styled-components";

export const LKInput = React.memo(props => <Input {...props} />);

const LKStyledInput = styled(Input)`
    border: none !important;
    background-color: #eee !important;
    padding: 0.8rem !important;
    height: 45px !important;
    border-radius: ${props => props.radius || "0 !important"};
    &[disabled] {
        color: rgba(0, 0, 0, 0.25) !important;
        cursor: not-allowed !important;
        opacity: 0.5 !important;
    }
`;
const Division = styled.div`
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    min-height: 1.5rem;
    padding-left: 1.9rem;
    margin-right: 1rem;
    margin-left: 0;
    .fill-control-indicator {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border: 2px solid #999;
        transition: 0.3s;
        background: transperent;
        background-size: 0%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
        &:hover {
            border-color: #666;
        }
        &.radio {
            border-radius: 55px;
        }
    }
`;
const LKStyledCheckbox = styled(Input)`
    display: none;
    &:checked {
        ~ .fill-control-indicator {
            background-color: #666;
            border-color: #666;
            background-size: 80%;
        }
    }
`;
const PrimaryInput = React.memo(props => {
    let Ret;
    switch (props.type) {
        case "checkbox":
            Ret = (
                <Division>
                    <Label check>
                        <LKStyledCheckbox {...props} />
                        <span className="fill-control-indicator" />
                        <span>{props.label}</span>
                    </Label>
                </Division>
            );
            break;
        case "radio":
            Ret = (
                <Division>
                    <Label check>
                        <LKStyledCheckbox {...props} />
                        <span className="fill-control-indicator radio" />
                        <span>{props.label}</span>
                    </Label>
                </Division>
            );
            break;

        default:
            Ret = <LKStyledInput fluid {...props} />;
            break;
    }
    return Ret;
});

PrimaryInput.propTypes = {
    type: PropTypes.string.isRequired,
    radius: PropTypes.string,
    disabled: PropTypes.bool
};
PrimaryInput.defaultProps = {
    disabled: false,
    radius: "0"
};
export default PrimaryInput;
