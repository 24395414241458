const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
const SET_ALL_LOCATIONS = "SET_ALL_LOCATIONS";
const SET_STORE_PHONE = "SET_STORE_PHONE";

export const setSelectedLocation = data => ({
    type: SET_SELECTED_LOCATION,
    data
});

export const setAllLocations = data => ({
    type: SET_ALL_LOCATIONS,
    data
});

export const setStorePhone = data => ({
    type: SET_STORE_PHONE,
    data
});

const initialState = {
    selectedLocation: [],
    allLocations: [],
    storePhone: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_SELECTED_LOCATION:
            return {
                ...state,
                selectedLocation: action.data
            };
        case SET_ALL_LOCATIONS:
            return {
                ...state,
                allLocations: action.data
            };
        case SET_STORE_PHONE:
            return {
                ...state,
                storePhone: action.data
            };
        default:
            return state;
    }
};
