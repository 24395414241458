import React from "react";
import PropTypes from "prop-types";
import PlacesAutocomplete from "react-places-autocomplete";
import { Input } from "components/ui";
import "./location-search-input.scss";

const LocationSearchInput = React.memo(props => {
    const { address, handleChange, handleSelect, disabled } = props;

    return (
        <PlacesAutocomplete
            searchOptions={{ types: ["address"] }}
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
        >
            {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading
            }) => (
                <div>
                    <Input
                        {...getInputProps({
                            placeholder: "Enter your delivery address",
                            name: "address",
                            className: "location-search-input",
                            disabled
                        })}
                    />
                    <div
                        className={`autocomplete-dropdown-container ${
                            suggestions.length > 0 ? "active" : ""
                        }`}
                    >
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                            const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                            // // inline style for demonstration purpose
                            // const style = suggestion.active
                            //     ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                            //     : { backgroundColor: '#ffffff', cursor: 'pointer' };

                            return (
                                <div
                                    key={suggestion.id}
                                    {...getSuggestionItemProps(suggestion, {
                                        className
                                    })}
                                >
                                    <span>{suggestion.description}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    );
});

LocationSearchInput.propTypes = {
    address: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    handleSelect: PropTypes.func.isRequired
};
LocationSearchInput.defaultProps = {
    disabled: false
};
export default LocationSearchInput;
