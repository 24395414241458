/* eslint-disable no-useless-rename */
/* eslint-disable react/prop-types */
import React, { Suspense } from "react";
import Spinner from "components/Spinner/Spinner";

const LoaderComponent = () => (
    <main>
        <div className="loading-container text-center">
            <Spinner />
        </div>
    </main>
);
const LazyLoad = ({ component: Component, loader: Loader, ...rest }) => (
    <>
        <Suspense fallback={Loader ? <Loader /> : <LoaderComponent />}>
            <Component {...rest} />
        </Suspense>
    </>
);

export default LazyLoad;
