import styled from "styled-components";

const StyledMenu = styled.div`
    ${({ theme }) => {
        return `
    background: #fff;
    z-index: 1;
    text-align: left;
    width: 100%;
    min-height: 100%;
    outline: none;
    background-color: #fff;
    display: flex;
    padding-bottom: 85px;
    position: relative;

    .link-mobile{
        display: flex;
        align-items: center;
        font-size: 1.25em;
        line-height: 1.25em;
        font-weight: 500;
        color: #333;
        padding: 1rem 2rem;
        transition: color 0.3s linear;
        border-left: 4px solid transparent;
        outline: none;
        
        a{
             color: #333;
        }
        .icon-link {
            margin-right: 16px;
        }
        &:hover {
            color: var(--black);
        }
        &.active-link {
            color:${theme.colors.primary.normal};
            background-color: #fafafa;
            border-left: 4px solid ${theme.colors.primary.normal};
        }
        &.active-link a{
            color:${theme.colors.primary.normal};
        }
        &.contact {
            padding-left: 67px;
            flex-direction: row;
        }
        &.catalog {
            justify-content: space-between;
        }
        .icon-link-arrow {
            display: flex;
            justify-content: center;
            align-items: center;

            &.active-arrow {
                transform: rotate(180deg);
            }
        }
        &.logOut {
            position: absolute;
            bottom: 10px;
            left: 0;
            width: 100%;
            border-top: 1px solid #e5e5e5;
        }
        &.open-subcategories + .categories-mobile-menu{
            display: block;
        }
        &.open-subcategories{
            .icon-link-arrow{
                transform: rotate(180deg);
            }
        }
    }
    .categories-mobile-menu {
        display: none;

        &.open-categ{
            display: block;
        }
        li {
            list-style: none;
            border-left: 2px solid #e5e5e5;

            .link-mobile {
                justify-content: space-between;
                display: flex;

                &.active-link {
                    margin-left: -1px;
                }
                &.full-width{
                    padding: 0;
                    
                    a{
                        width: 100%;
                        padding: 1rem 2rem;
                    }
                }
            }
        }
    }
    .top_mobile_menu {
        padding: 1rem 2rem;
        border-bottom: 1px solid #e5e5e5;

        .logo-button {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1.5rem;

            button img {
                transform: rotate(180deg);
                cursor: pointer;
            }
        }
        .user-name {
            font-size: 24px;
            font-weight: 500;
        }
        .header__account-btn {
            width: 48%;
        }
    }
`;
    }}
`;
export default StyledMenu;
