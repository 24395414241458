import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const LKStyledCheckbox = styled.div`
    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"]:disabled + span.label,
    input[type="checkbox"]:disabled + span.label:before {
        cursor: unset;
        color: ${({ theme }) => theme.colors.mono[600]};
    }
    input[type="checkbox"]:disabled + span.label:before {
        cursor: unset;
        border-color: ${({ theme }) => theme.colors.mono[600]};
    }
    input[type="checkbox"] + span.label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 20px;
        font: 20px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
    }

    input[type="checkbox"] + span.label:last-child {
        margin-bottom: 0;
    }

    input[type="checkbox"] + span.label:before {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        border: 1px solid ${({ theme }) => theme.colors.mono[700]};

        position: absolute;
        left: 0;
        top: 0;
        opacity: 0.6;
        -webkit-transition: all 0.12s, border-color 0.08s;
        transition: all 0.12s, border-color 0.08s;
    }

    input[type="checkbox"]:checked + span.label:before {
        width: 10px;
        top: -5px;
        left: 5px;
        border-radius: 0;
        opacity: 1;
        border-top-color: transparent;
        border-left-color: transparent;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;
const PrimaryCheckbox = ({ name, onChange, disabled, label, ...props }) => (
    <LKStyledCheckbox {...props}>
        <label htmlFor={name}>
            <input
                disabled={disabled}
                id={name}
                type="checkbox"
                onChange={onChange}
            />
            <span className="label">{label}</span>
        </label>
    </LKStyledCheckbox>
);
PrimaryCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};
export default PrimaryCheckbox;
