import cookies from "react-cookies";

const LOGIN_TOKEN_STORAGE_KEY = "auth_token";
const SELECTED_LOCATION = "selected_location";
const LOGICKIT_GUID = "lg_guid";

/**
 * Serialize javascript object for sending to api
 * @param {Object} data
 * @returns {String}
 */
export function serialize(data) {
    if (!data) {
        return false;
    }

    return Object.keys(data)
        .map(keyName => {
            if (data[keyName]) {
                return `${encodeURIComponent(keyName)}=${
                    data[keyName] ? encodeURIComponent(data[keyName]) : ""
                }`;
            }

            return false;
        })
        .join("&");
}

/**
 * Validate email address
 * @param {String} email
 * @returns {Boolean}
 */
export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(email);
}

/**
 * Validate passwords
 * @param {String} password
 * @returns {Boolean}
 */
export function validatePassword(password) {
    // const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return password.length >= 8;
}

/**
 * save Selected Location Object on Cookie
 */
export function saveLocationOnCookie(object) {
    return cookies.save(SELECTED_LOCATION, object, { path: "/" });
}
/**
 * Load Selected Location
 */
export function LoadLocationOnCookie() {
    return cookies.load(SELECTED_LOCATION, { path: "/" });
}
/**
 * save GUID from selected location endpoint
 */
export function saveGUIDCookie(object) {
    return cookies.save(LOGICKIT_GUID, object, { path: "/" });
}
/**
 * Load GUID from selected location endpoint
 */
export function LoadGUIDCookie() {
    return cookies.load(LOGICKIT_GUID, { path: "/" });
}
/**
 * Remove GUID from selected location endpoint
 */
export function removeGUIDCookie() {
    return cookies.remove(LOGICKIT_GUID, { path: "/" });
}
/**
 * Calculate promo code amount
 * @param {Number} price
 * @param {Number} discount
 * @returns {Number}
 */
export function discountCalculator(price, discount) {
    return price - discount;
}

/**
 * Parse sort queries
 * @param {String} type
 * @returns {Object}
 */
export function parseSortTypes(type) {
    let query = null;
    let title = "";

    switch (type) {
        case "price-asc":
            query = { sort_by: "price", direction: "asc" };
            title = "Lowest Price";
            break;
        case "price-desc":
            query = { sort_by: "price", direction: "desc" };
            title = "Highest Price";
            break;
        case "date-asc":
            query = { sort_by: "date", direction: "asc" };
            title = "Oldest Date";
            break;
        case "date-desc":
            query = { sort_by: "date", direction: "desc" };
            title = "Newest Date";
            break;
        default:
            break;
    }

    return {
        query,
        title
    };
}

/**
 * Save auth token in cookies
 * @param {String} token
 */
export function saveAuthToken(token) {
    return cookies.save(LOGIN_TOKEN_STORAGE_KEY, token, { path: "/" });
}
/**
 * check if user is logged in or not
 */
export function isLogined() {
    const authToken = cookies.load(LOGIN_TOKEN_STORAGE_KEY, { path: "/" });
    if (authToken && authToken !== "null") {
        return true;
    }
    return false;
}
/**
 * Return auth token from cookies
 */
export function loadAuthToken() {
    return cookies.load(LOGIN_TOKEN_STORAGE_KEY, { path: "/" });
}
/**
 * Remove auth token from cookies
 */
export function removeAuthToken() {
    return cookies.remove(LOGIN_TOKEN_STORAGE_KEY, { path: "/" });
}

/**
 * Convert objects to FormData
 * @param {Object} object
 */
export function getFormData(object) {
    return Object.keys(object).reduce((formData, key) => {
        formData.append(key, object[key]);

        return formData;
    }, new FormData());
}
