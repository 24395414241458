export const apiUrl = process.env.API_URL;
export const apiUrlGoogle = process.env.API_URL_GOOGLE;
export const storeName = process.env.STORE_NAME;
export const storeTitle = process.env.STORE_TITLE;
export const zendeskKey = process.env.ZENDESK_KEY;
export const isKiosk = process.env.REACT_APP_IS_KIOSK === "1";
export const themeName = process.env.THEME_NAME ? process.env.THEME_NAME : "";
export const contactPageData = process.env.CONTACT_PAGE
    ? JSON.parse(`${process.env.CONTACT_PAGE}`)
    : [];
export const googleMapToken = "AIzaSyBMUEMGQOAL316LAM3-Q2eNhGRDWNoEaVA";
export const MapBoxToken =
    "pk.eyJ1IjoibG9naWNraXQiLCJhIjoiY2p6Nncxa3N0MDI3YzNobXZiM2duNTdoeCJ9.9L_GHeTso00ghOvUHCcJzA";

export const breakPoints = {
    small: 576,
    xsmall: 630,
    medium: 768,
    large: 992,
    extra: 1200,
    extrabig: 1900
};

export const toastTimeOut = 5000;
export const lazyLoadingOffset = 350;

export const noImage =
    "https://www.cbkhardware.com/pub/media/catalog/product/placeholder/default/noimage-1.png";
