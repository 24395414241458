/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Container from "reactstrap/lib/Container";
import { Link } from "react-router-dom";
import { Button } from "components/ui";
import { getInfluencerInfo } from "api/cart";
import { closeModal } from "store/modules/modal";
import { IconClose } from "components/ui/Icon";
import MediaQuery from "react-responsive";
import "./referrer-modal.scss";

const REFERRER_PROGRAM_INFO = "REFERRER_PROGRAM_INFO";

const ReferrerProgramModal = React.memo(props => {
    const [initialized, setInitialized] = useState(false);
    const [influencerInfo, setInfluencerInfo] = useState(false);
    const { profileData, dispatch } = props;
    const copySpan = useRef();

    const loadInfluencerInfo = () => {
        getInfluencerInfo().then(response => {
            setInfluencerInfo(response);
        });
    };
    useEffect(() => {
        if (!initialized) {
            loadInfluencerInfo();
            setInitialized(true);
        }
    });
    const handleSubmit = e => {
        e.preventDefault();
        try {
            localStorage.setItem(
                REFERRER_PROGRAM_INFO + profileData.id,
                "true"
            );
        } catch (err) {
            return;
        } finally {
            dispatch(closeModal());
        }
    };

    const handleClick = () => {
        dispatch(closeModal());
    };

    const copyText = () => {
        const copySpanText = copySpan.current;
        const textArea = document.createElement("textarea");

        textArea.value = copySpanText.textContent;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        textArea.remove();
        const tooltip = document.getElementById("tool");

        tooltip.classList.add("active");
        setTimeout(() => {
            tooltip.classList.remove("active");
        }, 1000);
    };
    const {
        referral_phone: referralPhone,
        bonus_percent: bonusPercent,
        discount_percent: discountPercent
    } = influencerInfo;
    return (
        <div className="referrer__modal">
            <Container>
                <h2 className="referrer__modal__heading">
                    🎉 Referral Program is here!
                </h2>
                <p className="referrer__modal__copy">
                    {`Share ${profileData.store_name} with friends and earn store credit to your
                    balance.`}
                </p>
                <p className="referrer__modal__copy">
                    {`Once they use it, you get ${bonusPercent}% of the purchase credited to you, and they get ${discountPercent}% off their entire order!`}
                </p>
                <h2 ref={copySpan} className="hidden">
                    {`${profileData.first_name} ${profileData.last_name} would like to offer you a discount for signing up at ${profileData.store_name}.  Use this link and save ${discountPercent}% on your first order: https://${window.location.hostname}/referr/${referralPhone}`}
                </h2>
                <div className="buttons">
                    {!referralPhone && (
                        <Link to="/myaccount" onClick={handleClick}>
                            <Button>
                                Update phone number to use referral program
                            </Button>
                        </Link>
                    )}
                    {referralPhone && (
                        <MediaQuery maxWidth={768}>
                            <a
                                className="button btn btn-secondary mod1"
                                href={`sms:?&body=${`Hey 👋  I've been shopping on ${profileData.store_name} for cannabis delivery, now they're offering ${bonusPercent}% off for my personal referrals. You get 15% off your order and I get ${discountPercent}% credit to my account. It's a win win! Check it out: https://${window.location.hostname}/referr/${referralPhone}`}`}
                            >
                                <Button>Share Referral Link</Button>
                            </a>
                        </MediaQuery>
                    )}
                    <span className="tooltiptext" id="tool">
                        Text copied to clipboard
                    </span>
                    {referralPhone && (
                        <MediaQuery minWidth={768}>
                            <Button onClick={copyText}>
                                Share Referral Link
                            </Button>
                        </MediaQuery>
                    )}
                </div>
                <div className="cta" onClick={handleSubmit}>
                    <IconClose color="var(--gray6)" height="24" width="24" />
                </div>
            </Container>
        </div>
    );
});

ReferrerProgramModal.propTypes = {
    profileData: PropTypes.objectOf.isRequired,
    dispatch: PropTypes.func.isRequired
};
const mapStateToProps = ({ general, authentication }) => ({
    general: general.general,
    profileData: authentication.profileData,
    referralProgram: general.general.features.referral_program
});
export default connect(mapStateToProps)(ReferrerProgramModal);
