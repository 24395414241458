import React from "react";
import { bool, func } from "prop-types";
import BurgIcon from "images/burg-icon.svg";
import StyledBurger from "./Burger.styled";

const Burger = React.memo(({ open, setOpen, ...props }) => {
    const isExpanded = !!open;

    return (
        <StyledBurger
            aria-expanded={isExpanded}
            aria-label="Toggle menu"
            onClick={() => setOpen(!open)}
            {...props}
        >
            <img alt="" src={BurgIcon} />
        </StyledBurger>
    );
});

Burger.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired
};

export default Burger;
