import styled from "styled-components";

const StyledFooter = styled.div`
    ${({ theme }) => {
        return `
    .footer {
        display: flex;
        flex-direction: column;
        background-color: ${theme.colors.footerBackground};
        color: ${theme.colors.dark};

    &__columns {
        display: flex;
        justify-content: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        transition: all 0.3s;
        padding: 32px 0;

        &-ul {
            display: flex;
            list-style: none;
            margin: 0;
            padding: 0;
            align-items: center;
            flex-direction: row;

            @media screen and (max-width: 768px) {
                flex-direction: column;
            }

            li {
                margin: 16px 32px;
            }

            a {
                white-space: nowrap;
                color: ${theme.colors.light};
                font-weight: 400;
                font-size: 16px;
                transition: all 0.3s;

                &:hover {
                    color: ${theme.colors.info};
                }
            }
        }
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        padding: 16px 0;
        justify-content: left;

        @media screen and (max-width: 768px) {
            justify-content: space-between;
        }
        &--license {
            display: flex;
            flex-direction: row;
            margin-left: 40px;

            @media screen and (max-width: 768px) {
                margin-left: 0;
            }
            span {
                text-align: left;
                align-self: left;
                margin-left: 5px;
                font-size: 16px;
                color: ${theme.colors.mono[400]};
            }
        }
        &--section {
            display: flex;
            flex-direction: row;
            margin-left: 40px;

            @media screen and (max-width: 768px) {
                flex-direction: column;
                margin-left: 0;
            }

            &-logo {
                height: 40px;
                align-self: center;
            }

            &-copyRight {
                white-space: nowrap;
            }

            &-logicKitLogo {
                align-self: center;
                height: 16px;
            }

            span {
                text-align: center;
                align-self: center;
                margin-left: 16px;
                font-size: 16px;
                color: ${theme.colors.mono[400]};

                @media screen and (max-width: 768px) {
                    margin-left: 0;
                }
            }

            img {
                margin: 12px 0;
            }
        }
    }
    }`;
    }}
`;
export default StyledFooter;
