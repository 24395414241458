/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";
import { push } from "react-router-redux";
import { slide as Menu } from "react-burger-menu";
import { IoIosArrowRoundForward } from "react-icons/io";
import CartContainer from "containers/Cart/CartContainer";
import { breakPoints } from "config/constants";
import { Img } from "components/ui";
import { isLogined } from "utils/helpers";
import { enqueueModal } from "store/modules/modal";
import LoginContainer from "containers/Login/LoginContainer";
import HeaderLocationContainer from "./HeaderLocation/HeaderLocationContainer";
import HeaderNav from "./HeaderNav";
import HeaderAccount from "./HeaderAccount";
import MobileHeader from "./MobileHeader";
import HeaderSearchForm from "./SearchForm/HeaderSearchForm";
import "./header.scss";

const Header = React.memo(props => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [searchVisible, setSearchVisible] = useState(false);
    const {
        location,
        general,
        cartItemsLength,
        categories,
        userInfo,
        dispatch
    } = props;
    const goToCheckout = () => {
        setMenuIsOpen(false);
        dispatch(push("/checkout"));
    };

    const toggleMenu = () => {
        setMenuIsOpen(!menuIsOpen);
    };
    const menuStateChange = state => {
        setMenuIsOpen(state.isOpen);
    };

    const openLoginModal = form => {
        dispatch(
            enqueueModal({
                title: "",
                classes: "login-container",
                data: <LoginContainer form={form} />
            })
        );
    };
    const isAuthed = isLogined();
    useEffect(() => {
        if (location.search === "?reg" && !isAuthed) {
            dispatch(push("/"));
            dispatch(
                enqueueModal({
                    title: "",
                    classes: "login-container",
                    data: <LoginContainer form="register" />
                })
            );
        }
    }, [location]);

    return (
        <>
            <HeaderLocationContainer dispatch={dispatch} isAuthed={isAuthed} />
            <div className="header">
                <MediaQuery maxWidth={breakPoints.medium}>
                    <MobileHeader
                        cartItems={cartItemsLength}
                        categories={categories}
                        dispatch={dispatch}
                        isAuthed={isAuthed}
                        location={location}
                        logo={general.logo}
                        openLoginModal={openLoginModal}
                        searchCategory={location.search}
                        searchVisible={searchVisible}
                        setSearchVisible={setSearchVisible}
                        toggleMenu={toggleMenu}
                        userInfo={userInfo}
                    />
                </MediaQuery>

                <MediaQuery minWidth={breakPoints.medium + 1}>
                    <Container>
                        <Row>
                            <Link className="header__logo" to="/">
                                <Img alt="" lazy={false} src={general.logo} />
                            </Link>
                            <HeaderNav />
                            <HeaderAccount
                                cartItems={cartItemsLength}
                                isAuthed={isAuthed}
                                openLoginModal={openLoginModal}
                                setSearchVisible={setSearchVisible}
                                toggleMenu={toggleMenu}
                            />
                            {searchVisible && (
                                <HeaderSearchForm
                                    setSearchVisible={setSearchVisible}
                                />
                            )}
                        </Row>
                    </Container>
                </MediaQuery>
            </div>

            <Menu
                right
                bodyClassName="noscroll"
                crossButtonClassName="cart__header--close"
                customBurgerIcon={false}
                customCrossIcon={<IoIosArrowRoundForward size="32" />}
                isOpen={menuIsOpen}
                menuClassName="header__cart-menu"
                overlayClassName="header__cart-menu--overlay"
                width="500px"
                onStateChange={state => menuStateChange(state)}
            >
                {menuIsOpen && (
                    <CartContainer
                        dispatch={dispatch}
                        goToCheckout={goToCheckout}
                    />
                )}
            </Menu>
        </>
    );
});

Header.propTypes = {
    location: PropTypes.objectOf.isRequired,
    general: PropTypes.objectOf.isRequired,
    userInfo: PropTypes.objectOf.isRequired,
    cartItemsLength: PropTypes.number.isRequired,
    dispatch: PropTypes.func.isRequired,
    categories: PropTypes.arrayOf.isRequired
};
const mapStateToProps = ({
    authentication,
    general,
    categories,
    userInfo
}) => ({
    cartItemsLength: authentication.cartItemsLength,
    general: general.general,
    categories: categories.categories,
    userInfo: userInfo.userInfo.user
});

export default connect(mapStateToProps)(Header);
