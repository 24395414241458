import request from "utils/request";

/**
 * Get all products
 * @param {Object} params
 * @returns {Promise}
 */
export function getProducts(params) {
    return request(`products`, params, "GET");
}

/**
 * Get front page products - Again, I'm using constant id.
 * @returns {Promise}
 */
export function getFrontPageProducts() {
    return request(`products/grouped_by/category`, null, "GET");
}

/**
 * Make search on products
 * @param {String} string
 * @returns {Promise}
 */
export function searchProducts(string) {
    return request(`search`, { type: "product", query: string }, "GET");
}

/**
 * Get products by id
 * @param {Number} id
 * @returns {Promise}
 */
export function getProductById(id) {
    return request(`products/${id}`, null, "GET");
}

/**
 * Get all brands
 * @returns {Promise}
 */
export function getAllBrands(categ) {
    return request(
        `brands${categ ? `?category_id=${categ}` : ""}`,
        null,
        "GET"
    );
}
