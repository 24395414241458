const SET_ACTIVE_MODAL = "SET_ACTIVE_MODAL";

function setModalData(data, visible) {
    return {
        type: SET_ACTIVE_MODAL,
        modalData: data.length > 0 ? data[0] : {},
        isVisible: visible
    };
}

export function enqueueModal(options) {
    return async dispatch => {
        const data = [];

        data.push({
            data: options.data,
            title: options.title,
            classes: options.classes || ""
        });

        await dispatch(setModalData(data, true));
    };
}

export function closeModal() {
    return async dispatch => {
        const data = [];
        data.push({
            data: {}
        });
        await dispatch(setModalData(data, false));
    };
}

const initialState = {
    modalData: {},
    isModalVisible: false
};

export default function modal(state = initialState, action) {
    switch (action.type) {
        case SET_ACTIVE_MODAL:
            return {
                modalData: action.modalData,
                isModalVisible: action.isVisible
            };
        default:
            return state;
    }
}
