const SET_ALL_CAROUSELS = "SET_ALL_CAROUSELS";

export const setAllCarousel = data => ({
    type: SET_ALL_CAROUSELS,
    data
});

const initialState = {
    carousels: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_ALL_CAROUSELS:
            return {
                ...state,
                carousels: action.data
            };

        default:
            return state;
    }
};
