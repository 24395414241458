import request from "utils/request";

/**
 * Update user profile
 * @param {Object} data
 * @returns {Promise}
 */
export function updateUser(data) {
    return request("update_user_info", data, "PUT");
}

/**
 * create new user address
 * @param {Object} data
 * @returns {Promise}
 */
export function createNewAddress(data) {
    return request("locations/", data, "POST");
}

/**
 * Update user address
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateAddress(id, data) {
    return request(`locations/${id}`, data, "PUT");
}

/**
 * Send verification email
 * @param {Object} data
 * @returns {Promise}
 */
export function verifyEmail(data) {
    return request("confirmation", data, "GET");
}

/**
 * re-send verification email
 * @returns {Promise}
 */
export function reSendEmail() {
    return request("resend_email", null, "GET");
}

/**
 * Send verification SMS
 * @returns {Promise}
 */
export function sendVerification() {
    return request("user/verification", null, "POST");
}

/**
 * Verify cell phone
 * @param {Object} data
 * @returns {Promise}
 */
export function verifyPhoneCode(data) {
    return request("user/verification/check", data, "POST");
}

/**
 * Request resest password
 * @param {Object} data
 * @returns {Promise}
 */
export function requestResetPassword(data) {
    return request("password", data, "POST");
}

/**
 * confirm resest password
 * @param {Object} data
 * @returns {Promise}
 */
export function confirmResetPassword(data) {
    return request("password", data, "PUT");
}

/**
 * Upload customer documents
 * @param {FormData} data
 * @returns {Promise}
 */
export function uploadDocuments(data) {
    return request("user/documents", data, "PUT");
}

/**
 * Upload customer documents
 * @param {FormData} data
 * @returns {Promise}
 */
export function uploadMedicalDocuments(data) {
    return request("user/medical_documents", data, "PUT");
}

/**
 * Upload files
 * @param {Object} data
 * @returns {Promise}
 */
export function upload(data) {
    return request("upload", data, "POST");
}

/**
 * accept Term of use
 * @param {FormData} data
 * @returns {Promise}
 */
export function acceptTermOfUse(data) {
    return request("user/tou", data, "PUT");
}

/**
 * Find closest retailers
 * @param {Object} data
 * @returns {Promise}
 */
export function findClosestRetailer(data) {
    return request("retailers/closest", data, "POST");
}

/**
 * Get orders history
 * @returns {Promise}
 */
export function getOrderHistory() {
    return request("order_history", null, "GET");
}

/**
 * Update user phone number
 * @param {Object} data
 * @returns {Promise}
 */
export function updatePhoneNumber(data) {
    return request("user/update/phone", data, "POST");
}

/**
 * Get User Cash Balance
 * * @param {Object} data
 * @returns {Promise}
 */
export function getReferralDiscountAmount(data) {
    return request("customer_referral_discount", data, "GET");
}
