import React from "react";
import PropTypes from "prop-types";
import Select from "@logickit/logickit-decore/build/lib/components/Select";
import styled from "styled-components";

const LKStyledSelect = styled(Select)`
    border: none !important;
    background-color: #eee !important;
    padding: 0.8rem !important;
    height: 45px !important;
    border-radius: ${props => props.radius || "0 !important"};
    &[disabled] {
        color: rgba(0, 0, 0, 0.25) !important;
        cursor: not-allowed !important;
        opacity: 0.5 !important;
    }
`;
const PrimarySelect = React.memo(({ children, ...props }) => (
    <LKStyledSelect {...props}>{children}</LKStyledSelect>
));

PrimarySelect.propTypes = {
    children: PropTypes.element.isRequired
};
export default PrimarySelect;
