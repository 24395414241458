/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import FormGroup from "reactstrap/lib/FormGroup";
import { Button } from "components/ui";
import { Link } from "react-router-dom";
import { storeTitle } from "config/constants";

const RegisterForm = React.memo(props => {
    const referralCode = localStorage.getItem("referralCode");
    // eslint-disable-next-line react/prop-types
    const { Field, AdaptedInput, referralProgram } = props;
    return (
        <>
            {referralCode && (
                <div className="referral_code">
                    <span>Your referral code:</span> {referralCode}
                </div>
            )}
            {(!referralCode || referralProgram) && (
                <FormGroup>
                    <Field
                        component={AdaptedInput}
                        defaultValue={referralCode || null}
                        name="referralCode"
                        placeholder="Referral code"
                        type="text"
                    />
                </FormGroup>
            )}
            <FormGroup>
                <Field
                    autoFocus
                    required
                    component={AdaptedInput}
                    name="firstname"
                    placeholder="First Name"
                />
            </FormGroup>
            <FormGroup>
                <Field
                    required
                    component={AdaptedInput}
                    name="lastname"
                    placeholder="Last Name"
                />
            </FormGroup>
            <FormGroup>
                <Field
                    required
                    component={AdaptedInput}
                    name="email"
                    placeholder="Email Address"
                    type="email"
                    typeMismatch="Please enter an valid email address"
                />
            </FormGroup>
            {/* <FormGroup>
                <Field
                    name="user_type"
                    fluid
                    required
                    component={AdaptedSelect}
                >
                    <option value="" selected disabled>
                        Select your registration type
                    </option>
                    <option value="recreational">
                        I am a recreational user
                    </option>
                    <option value="medical">I am a medical user</option>
                </Field>
            </FormGroup> */}
            <FormGroup>
                <Field
                    required
                    component={AdaptedInput}
                    minLength={8}
                    name="password"
                    placeholder="Password"
                    tooShort="Your password must be at least 8 characters long"
                    type="password"
                />
            </FormGroup>
            <FormGroup>
                <Field
                    required
                    component={AdaptedInput}
                    name="password_confirmation"
                    placeholder="Confirm Password"
                    type="password"
                />
            </FormGroup>
            <FormGroup>
                <label>
                    <span>
                        By providing your contact details, you agree to the{" "}
                        <Link target="_blank" to="/terms">
                            Terms and Conditions
                        </Link>{" "}
                        and{" "}
                        <Link target="_blank" to="/privacy">
                            Privacy & cookies notice
                        </Link>
                    </span>
                </label>
            </FormGroup>
            <FormGroup>
                <label>
                    <span>
                        I agree to receive other communications from{" "}
                        {storeTitle}
                    </span>
                </label>
            </FormGroup>
            <FormGroup className="button-group">
                <Button fluid className="login__modal-btn" type="submit">
                    Register
                </Button>
            </FormGroup>
        </>
    );
});
export default RegisterForm;
