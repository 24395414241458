import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import FormGroup from "reactstrap/lib/FormGroup";
import Container from "reactstrap/lib/Container";
import { Button } from "components/ui";
import { acceptTermOfUse } from "api/user";
import { closeModal } from "store/modules/modal";

const TOUModal = props => {
    const [isProcessing, setIsProcessing] = useState(false);
    const { dispatch } = props;
    const handleSubmit = () => {
        setIsProcessing(true);
        acceptTermOfUse()
            .then(() => {
                dispatch(closeModal());
                setIsProcessing(false);
            })
            .catch(() => {
                setIsProcessing(false);
            });
    };
    return (
        <Container>
            <StyledTOUModal>
                <p>
                    please read the{" "}
                    <a href="/terms" target="_blank">
                        term of use
                    </a>
                    .
                </p>

                <FormGroup>
                    <Button
                        disabled={isProcessing}
                        loading={isProcessing}
                        type="submit"
                        onClick={handleSubmit}
                    >
                        I Agree
                    </Button>
                </FormGroup>
            </StyledTOUModal>
        </Container>
    );
};

const StyledTOUModal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;
TOUModal.propTypes = {
    dispatch: PropTypes.func.isRequired
};
export default TOUModal;
