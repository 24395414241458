import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "reactstrap/lib/Container";
import { Link } from "react-router-dom";
import { Img } from "components/ui";
import LogicKitLogo from "images/Logickit-white.png";
import StyledFooter from "./Footer.styled";

const Footer = React.memo(props => {
    const { general } = props;
    const licensesSyring =
        general && general.licenses && general.licenses.join(", ");
    return (
        <StyledFooter>
            <div className="footer">
                <Container fluid>
                    <div className="footer__columns">
                        <ul className="footer__columns-ul">
                            <li>
                                <Link to="/about">About us</Link>
                            </li>
                            <li>
                                <Link to="/contact">Contact us</Link>
                            </li>
                            <li>
                                <Link to="/terms">Terms & Conditions</Link>
                            </li>
                            <li>
                                <Link to="/privacy">Privacy policy</Link>
                            </li>
                        </ul>
                    </div>
                </Container>

                <div className="footer__divider" />

                <Container fluid>
                    <div className="footer__bottom">
                        <div className="footer__bottom--section">
                            <Img
                                alt=""
                                className="footer__bottom--section-logo"
                                src={general.logo}
                            />
                            <span className="footer__bottom--section-copyRight">
                                © 2019
                            </span>
                        </div>
                        {general && general.show_footer && (
                            <div className="footer__bottom--section">
                                <Img
                                    alt=""
                                    className="footer__bottom--section-logicKitLogo"
                                    src={LogicKitLogo}
                                />
                                <span>Delivery powered by LogicKit.io</span>
                            </div>
                        )}
                    </div>
                    {licensesSyring && (
                        <div className="footer__bottom">
                            <div className="footer__bottom--license">
                                <span>License:</span>
                                <span>{licensesSyring}</span>
                            </div>
                        </div>
                    )}
                </Container>
            </div>
        </StyledFooter>
    );
});

Footer.propTypes = {
    general: PropTypes.objectOf.isRequired
};
const mapStateToProps = ({ general }) => ({
    general: general.general
});

export default connect(mapStateToProps)(Footer);
