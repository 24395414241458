import { useEffect } from "react";

const useScript = (url, id) => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = url;
        script.async = false;
        script.id = id;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, [url]);
};
export default useScript;
