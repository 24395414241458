/* eslint-disable no-console */
import { hot } from "react-hot-loader/root";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import connect from "react-redux/es/connect/connect";
import "lazysizes";
import { Decore } from "@logickit/logickit-decore";
import { ScrollContainer } from "react-router-scroll-4";
import withRouter from "react-router-dom/withRouter";
import { ToastContainer } from "react-toastify";
import { Modal } from "components/ui";
import AppLoadingContainer from "containers/AppLoading/AppLoadingContainer";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import getRoutes from "config/routes";
import {
    toastTimeOut,
    storeTitle,
    zendeskKey,
    themeName
} from "config/constants";
import useScript from "utils/hooks/useScript";
import greenTheme from "styles/themes/stores/DefaultTheme";
import "bootstrap/dist/css/bootstrap.min.css";
// import theme from "styles/themes";
import "styles/style.scss";

const AppContainer = props => {
    const [theme1, setTheme] = useState("");
    const { location, dispatch, isAuthed } = props;
    if (zendeskKey && zendeskKey !== "")
        useScript(
            `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`,
            "ze-snippet"
        );
    const myAccount = window.location.href.includes("myaccount");
    const userOrders = window.location.href.includes("orders");
    const Checkout = window.location.href.includes("checkout");
    if (themeName && themeName !== "") {
        const theme2 = themeName;
        import(`../../styles/themes/stores/${theme2}`).then(props1 => {
            setTheme(props1.default);
        });
    } else {
        import("../../styles/themes/stores/GreenTheme").then(props1 => {
            setTheme(props1.default);
        });
    }
    return (
        <Decore theme={theme1 || greenTheme}>
            <div id="outer-container">
                <Helmet
                    defaultTitle={storeTitle}
                    titleTemplate={`%s - ${storeTitle}`}
                />
                <ScrollContainer scrollKey="page-wrap">
                    <div>
                        {!myAccount && !userOrders && !Checkout && (
                            <Header location={location} />
                        )}
                        <ErrorBoundary location={location}>
                            <AppLoadingContainer location={location}>
                                {getRoutes(dispatch, location, isAuthed)}
                            </AppLoadingContainer>
                        </ErrorBoundary>

                        <Footer />
                    </div>
                </ScrollContainer>

                <Modal />
                <ToastContainer
                    hideProgressBar
                    autoClose={toastTimeOut}
                    closeButton={false}
                    position="top-right"
                />
            </div>
        </Decore>
    );
};

AppContainer.propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.objectOf.isRequired,
    isAuthed: PropTypes.bool.isRequired
};
const mapStateToProps = ({ authentication }) => ({
    isAuthed: authentication.isAuthed
});

export default withRouter(connect(mapStateToProps)(hot(AppContainer)));
