/* eslint-disable react/no-did-update-set-state */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import * as toast from "utils/toast";
import { viewCart, deleteItem } from "api/cart";
import { setCartItem } from "store/modules/authentication";
import Cart from "./Cart";

const CartContainer = React.memo(props => {
    const [initialized, setInitialized] = useState(false);
    const [cartItems, setCartItems] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    const [removingProcess, setRemovingProcess] = useState(0);
    const { goToCheckout, dispatch } = props;
    const loadCart = () => {
        setIsLoading(true);
        viewCart()
            .then(response => {
                const { cart_items: items, total } = response;
                setCartItems(items);
                setTotalAmount(total);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };
    const updateTotalAmount = () => {
        viewCart().then(response => {
            const { total } = response;
            setTotalAmount(total);
        });
    };
    useEffect(() => {
        if (!initialized) {
            loadCart();
            setInitialized(true);
        }
    });

    const removeCartItem = async id => {
        setRemovingProcess(id);

        deleteItem({ cart_item_id: id })
            .then(() => {
                _.remove(cartItems, obj => obj.id === id);
                updateTotalAmount();
                dispatch(setCartItem(cartItems.length));
                toast.success({ text: "Item deleted successfully." });
                setRemovingProcess(0);
            })
            .catch(() => {
                setRemovingProcess(0);
            });
    };

    return (
        <Cart
            goToCheckout={goToCheckout}
            isLoading={isLoading}
            items={cartItems}
            removeCartItem={removeCartItem}
            removingProcess={removingProcess}
            totalAmount={totalAmount}
        />
    );
});
CartContainer.propTypes = {
    goToCheckout: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired
};
export default CartContainer;
