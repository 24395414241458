import styled from "styled-components";

const StyledLogin = styled.div`
    ${({ theme }) => {
        return `
    color: ${theme.colors.dark};
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-height: 630px) {
        overflow: auto;
    }

    .login__modal {
        &-oauth {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;

            &--divider {
                text-align: center;
                font-size: 16px;
                font-weight: 100;
                color: var(--gray4);
            }

            &--btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                height: 40px;
                border-radius: 3px;
                color: ${theme.colors.light};
                font-weight: 500;
                margin-top: 12px;

                &:hover {
                    color: ${theme.colors.mono[100]};


                }

                span.isvg {
                    margin-right: 1em;
                }

                &.--google {
                    color: #db504a;
                    border: 1px solid #db504a;
                    &:hover {
                        background: #db504a;
                        color:#fff;
                    }
                }

                &.--facebook {
                    color: #4d9de0;
                    border:1px solid #4d9de0;
                    &:hover {
                        border-color: #4d9de0;
                        color:#fff;
                    }
                }
            }
        }



        &-background {
            background-size: cover;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
        }

        &-information {
            display: flex;
            padding: 1rem 1rem 2rem 0;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;

            &--header {
                position: relative;
                width: 100%;
                display: flex;
                justify-content: space-between;
                border-bottom: 3px solid ${theme.colors.mono[300]};

                &_btn {
                    width: 50%;
                    font-weight: 500 !important;
                    border-radius: 0;
                    margin-bottom: -3px;
                    font-size: 18px;
                    padding: 0 0 0.6em;
                    letter-spacing: 0.05em;
                    color: ${theme.colors.mono[500]} !important;

                    &.is-active {
                        color: ${theme.colors.primary.normal} !important;
                        border-bottom: 3px solid ${theme.colors.primary.normal};
                    }

                    &:hover {
                        color: ${theme.colors.primary.normal} !important;
                    }
                }
            }

            &--fields {
                width: 100%;
            }
        }

        .form-check {
            display: flex !important;
            flex-direction: row;
            justify-content: space-between;

            .form-check-label {
                color: #111111;

                span {
                    font-weight: 400;
                    line-height: 25px;
                    font-size: 14px;
                    margin-left: 3px;
                }
            }
        }

        &-content {
            margin-top: 30px;
            position: relative;
            display: none;
            width: 100%;

            &.is-active {
                position: relative;
                display: block;
            }

            &--form {
                label {
                    text-align: left;
                    font-weight: normal;
                    font-size: 1.118rem;
                    margin-bottom: 0.2em;
                    color: ${theme.colors.mono[600]};
                }

                .password-group {
                    position: relative;

                    .showpassword-btn {
                        position: absolute;
                        right: 15px;
                        top: 15px;
                        z-index: 999;
                        color: ${theme.colors.mono[600]};
                        font-weight: 500;
                        font-size: 12px;
                        cursor: pointer;
                    }

                    .forgotpassword-btn {
                        margin-left: auto;
                        display: flex;
                        width:auto;
                        position: relative;
                        text-align: right;
                        padding: 8px 0 0 0;
                        font-size: 14px;
                        font-weight: 500;
                        color: ${theme.colors.info};
                    }
                }
            }
        }


        .button-group {
            display: flex;
            flex-direction: column;
            align-content: space-around;
            justify-content: space-between;
            margin-top: 40px;

            &-row {
                display: flex;
                flex-direction: row !important;
                justify-content: center !important;
            }

            @media screen and (max-width: 490px) {
                flex-direction: column;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
`;
    }}
`;

export default StyledLogin;
