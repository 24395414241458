import React, { useState } from "react";
import PropTypes from "prop-types";
import FormGroup from "reactstrap/lib/FormGroup";
import { Button, SimpleButton } from "components/ui";

const LoginForm = React.memo(props => {
    const [showPassword, setShowPassword] = useState(false);
    // eslint-disable-next-line react/prop-types
    const { Field, handleShowResetPassword, AdaptedInput } = props;

    const handleShowPassword = () => {
        setShowPassword(!showPassword);

        const el = document.getElementById("password-field");

        if (!showPassword) {
            el.setAttribute("type", "text");
        } else {
            el.setAttribute("type", "password");
        }
    };

    return (
        <>
            <FormGroup>
                <Field
                    autoFocus
                    required
                    component={AdaptedInput}
                    name="email"
                    placeholder="Email"
                    type="email"
                    typeMismatch="Please enter an valid email address"
                />
            </FormGroup>
            <FormGroup className="password-group">
                <Field
                    required
                    component={AdaptedInput}
                    id="password-field"
                    name="password"
                    placeholder="Password"
                    type="password"
                />
                <SimpleButton
                    className="forgotpassword-btn"
                    color="link"
                    onClick={handleShowResetPassword}
                >
                    Forgot password?
                </SimpleButton>
                <span className="showpassword-btn" onClick={handleShowPassword}>
                    {showPassword ? "Hide" : "Show"}
                </span>
            </FormGroup>
            <FormGroup className="button-group">
                <Button fluid className="login__modal-btn" type="submit">
                    Log In
                </Button>
            </FormGroup>
        </>
    );
});
LoginForm.propTypes = {
    handleShowResetPassword: PropTypes.func.isRequired
};
export default LoginForm;
