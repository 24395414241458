import { addItem, viewCart } from "api/cart";
import * as toast from "utils/toast";
import { setCartItem } from "./authentication";

const SET_BEST_SELLERS = "SET_BEST_SELLERS";
const SET_FRONT_PAGE_PRODUCTS = "SET_FRONT_PAGE_PRODUCTS";
const SET_BRANDS = "SET_BRANDS";

export const setBestSellers = data => ({
    type: SET_BEST_SELLERS,
    data
});

export const setFrontPageProducts = data => ({
    type: SET_FRONT_PAGE_PRODUCTS,
    data
});

export const setBrands = data => ({
    type: SET_BRANDS,
    data
});
export function addToCart(product, value) {
    return async dispatch => {
        const data = {
            product_id: product.id,
            quantity: value
        };

        addItem(data).then(async () => {
            const { cart_items: cartItems } = await viewCart();
            const cartLength = cartItems.reduce(
                (acc, val) => acc + val.quantity,
                0
            );
            dispatch(setCartItem(cartLength));
            toast.success({ text: "Successfuly added to your cart." });
        });
    };
}

const initialState = {
    brands: [],
    bestSellers: [],
    frontPageProducts: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_BEST_SELLERS:
            return {
                ...state,
                bestSellers: action.data
            };
        case SET_BRANDS:
            return {
                ...state,
                brands: action.data
            };
        case SET_FRONT_PAGE_PRODUCTS:
            return {
                ...state,
                frontPageProducts: action.data
            };

        default:
            return state;
    }
};
