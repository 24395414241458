import React from "react";
import Button from "@logickit/logickit-decore/build/lib/components/Button";
import SButton from "reactstrap/lib/Button";
import styled from "styled-components";

const StyledButton = styled(Button)`
    ${({ fluid }) => {
        const width = fluid ? "100%" : "auto";
        return `
    height: 40px;
    width:${width}
`;
    }}
`;
export const SimpleButton = React.memo(props => <SButton {...props} />);

const PrimaryButton = React.memo(({ ...props }) => <StyledButton {...props} />);

export default PrimaryButton;
