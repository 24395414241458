/* eslint-disable no-underscore-dangle */
import React from "react";
import { render } from "react-dom";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import Provider from "react-redux/es/components/Provider";
import {
    ConnectedRouter,
    routerReducer,
    routerMiddleware
} from "react-router-redux";
import { ScrollContext } from "react-router-scroll-4";
import thunk from "redux-thunk";
import "lazysizes";
import * as Sentry from "@sentry/browser";
import { ApolloProvider } from "react-apollo";
import AppContainer from "containers/App/AppContainer";
import ReactGA from "react-ga";
import * as reducers from "./store";
import client from "./ApolloClient";

// PWA
// let deferredPrompt;
// const addBtn = document.querySelector(".add-button");
// addBtn.style.display = "none";

// window.addEventListener("beforeinstallprompt", e => {
//     // Prevent Chrome 67 and earlier from automatically showing the prompt
//     e.preventDefault();
//     // Stash the event so it can be triggered later.
//     deferredPrompt = e;
//     // Update UI to notify the user they can add to home screen
//     addBtn.style.display = "block";

//     addBtn.addEventListener("click", () => {
//         // hide our user interface that shows our A2HS button
//         addBtn.style.display = "none";
//         // Show the prompt
//         deferredPrompt.prompt();
//         // Wait for the user to respond to the prompt
//         deferredPrompt.userChoice.then(choiceResult => {
//             if (choiceResult.outcome === "accepted") {
//                 // eslint-disable-next-line no-console
//                 // console.log("User accepted the A2HS prompt");
//             } else {
//                 // eslint-disable-next-line no-console
//                 // console.log("User dismissed the A2HS prompt");
//             }
//             deferredPrompt = null;
//         });
//     });
// });

const createHistory = require("history").createBrowserHistory;

Sentry.init({
    dsn: "https://134e506fe8ab42efb2d26f3bb6883a26@sentry.io/1472179"
});

const appReducer = combineReducers({ ...reducers, router: routerReducer });

// clear all stored state on logout
function rootReducer(state, action) {
    return appReducer(state, action);
}

const history = createHistory();
const historyMiddleware = routerMiddleware(history);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, historyMiddleware))
);

ReactGA.initialize(process.env.GA_ID);
ReactGA.pageview(window.location.pathname);
render(
    <ApolloProvider client={client}>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <ScrollContext>
                    <AppContainer />
                </ScrollContext>
            </ConnectedRouter>
        </Provider>
    </ApolloProvider>,
    document.getElementById("root")
);
// registerServiceWorker();
