import styled from "styled-components";

// const StyledBurger = styled.button`
//     display: flex;
//     flex-direction: column;
//     justify-content: space-around;
//     width: 30px;
//     height: 30px;
//     background: transparent;
//     border: none;
//     cursor: pointer;
//     padding: 0;
//
//     span {
//         width: 30px;
//         height: 3px;
//         border-radius: 30px;
//         background: ${({ open }) =>
//             open ? "rgba(0, 0, 0, 0.8)" : "rgba(0, 0, 0, 0.5)"};
//         border-radius: 10px;
//         transition: all 0.3s linear;
//         position: relative;
//         transform-origin: 1px;
//
//         :first-child {
//             transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
//         }
//
//         :nth-child(2) {
//             opacity: ${({ open }) => (open ? "0" : "1")};
//             transform: ${({ open }) =>
//                 open ? "translateX(20px)" : "translateX(0)"};
//         }
//
//         :nth-child(3) {
//             transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
//         }
//     }
// `;
const StyledBurger = styled.button`
    transform: rotate(180deg);
    display: block;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
`;
export default StyledBurger;
