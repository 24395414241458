import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { Modal, ModalHeader, ModalBody } from "@logickit/logickit-decore";
import { closeModal } from "store/modules/modal";

import styles from "./Modal.scss";

const LGModal = ({ dispatch, title, isVisible, classes, data }) => {
    const [Data, setData] = useState("");
    const fetchData = () => {
        setData(data);
    };
    useEffect(() => {
        fetchData(data);
    }, [data]);

    const handleCloseModal = () => {
        dispatch(closeModal());
    };
    const closeBtn = (
        <button className="close" type="button" onClick={handleCloseModal}>
            &times;
        </button>
    );
    return (
        <Modal
            className={`${styles.Modal} ${classes}`}
            isOpen={isVisible}
            toggle={handleCloseModal}
        >
            {isVisible && (
                <>
                    {closeBtn}
                    {title && <ModalHeader>{title}</ModalHeader>}
                    <ModalBody>{Data}</ModalBody>
                </>
            )}
        </Modal>
    );
};

LGModal.propTypes = {
    dispatch: PropTypes.func.isRequired,
    data: PropTypes.element.isRequired,
    isVisible: PropTypes.bool.isRequired,
    classes: PropTypes.string,
    title: PropTypes.string
};
LGModal.defaultProps = {
    classes: "",
    title: ""
};
function mapStateToProps({ modal }) {
    return {
        data: modal.modalData.data,
        title: modal.modalData.title,
        classes: modal.modalData.classes,
        isVisible: modal.isModalVisible
    };
}

export default connect(mapStateToProps)(LGModal);
