/* eslint-disable react/no-unused-prop-types */
import React from "react";
import PropTypes from "prop-types";
import Container from "reactstrap/lib/Container";
import Row from "reactstrap/lib/Row";

import { Button } from "components/ui";
import {
    LocationContainer,
    Division,
    StyledDiv
} from "./HeaderLocation.styled";

const HeaderLocation = React.memo(props => {
    const {
        selectedLocation,
        notInRange,
        currentLocation,
        onUpdateLocationClick,
        storePhone
    } = props;

    return (
        <LocationContainer>
            <Division notInRange={notInRange}>
                <Container fluid>
                    <Row className="justify-content-center">
                        <StyledDiv>
                            {selectedLocation && notInRange ? (
                                <span className="haserror">
                                    You seem to be in <b>{currentLocation}</b>,
                                    we do not deliver to your area yet.
                                </span>
                            ) : (
                                <span>
                                    Your delivery location is{" "}
                                    <b>{currentLocation}</b>
                                    {storePhone.phone && (
                                        <a
                                            className="phone"
                                            href={`tel:${storePhone.phone}`}
                                        >
                                            {" Order at: "}
                                            {storePhone.phone}
                                        </a>
                                    )}
                                </span>
                            )}
                        </StyledDiv>
                        {notInRange ? (
                            <Button
                                secondary
                                onClick={() => onUpdateLocationClick()}
                            >
                                Change Location
                            </Button>
                        ) : (
                            <Button onClick={() => onUpdateLocationClick()}>
                                Update Location
                            </Button>
                        )}
                    </Row>
                </Container>
            </Division>
        </LocationContainer>
    );
});
HeaderLocation.propTypes = {
    currentLocation: PropTypes.func.isRequired,
    onUpdateLocationClick: PropTypes.func.isRequired,
    selectedLocation: PropTypes.objectOf.isRequired,
    storePhone: PropTypes.objectOf.isRequired,
    notInRange: PropTypes.bool.isRequired
};

export default HeaderLocation;
