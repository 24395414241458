import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { IconUser } from "components/ui/Icon";

const HeaderUser = React.memo(() => {
    return (
        <Fragment>
            <Link className="header__account-info" to="/myaccount">
                <IconUser />
            </Link>
        </Fragment>
    );
});

export default HeaderUser;
