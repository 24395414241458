import React, { useState } from "react";
import PropTypes from "prop-types";
import Form from "reactstrap/lib/Form";
import FormGroup from "reactstrap/lib/FormGroup";
import Container from "reactstrap/lib/Container";
import { Input, Button } from "components/ui";
import { requestResetPassword } from "api/user";
import { validateEmail } from "utils/helpers";
import * as toast from "utils/toast";

import "./reset-password.scss";

const ResetPasswordModal = React.memo(props => {
    const [email, setEmail] = useState("");
    const [isProcessing, setIsProcessing] = useState(false);
    const { handleCloseModal } = props;
    const handleSubmit = async e => {
        e.preventDefault();

        if (!email || !validateEmail(email)) {
            toast.error({ text: "Please enter an valid email address." });
            return false;
        }

        try {
            setIsProcessing(true);
            const formData = new FormData();
            formData.append("app_user[email]", email);

            await requestResetPassword(formData);
            toast.success({
                text: "Please check your email for the reset password link"
            });
            handleCloseModal();
        } finally {
            setIsProcessing(false);
        }

        return true;
    };
    return (
        <Container>
            <div className="password__modal">
                <div className="password__modal-header">
                    <h2>Reset Password</h2>
                    <p>
                        Enter your email address below and a link will be sent
                        to you.
                    </p>
                </div>

                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Input
                            autoFocus
                            required
                            id="email"
                            name="email"
                            placeholder="Email"
                            type="text"
                            onChange={e => setEmail(e.target.value)}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button
                            fluid
                            className="password__modal-btn"
                            disabled={isProcessing}
                            loading={isProcessing}
                            type="submit"
                        >
                            Reset Password
                        </Button>
                    </FormGroup>
                </Form>
            </div>
        </Container>
    );
});

ResetPasswordModal.propTypes = {
    handleCloseModal: PropTypes.func.isRequired
};
export default ResetPasswordModal;
