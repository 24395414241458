import { lighten } from "polished";

const light = "#ffffff";
const dark = "#333333";

const success = "#3abf7c";
const error = "#DB504A";
const warning = "#FF8552";
const info = "#4D9DE0";
const disabled = "#E5E5E5";

export const colors = {
    success,
    error,
    warning,
    info,
    disabled,

    light,
    dark,

    mono: {
        700: dark,
        600: lighten(0.2, dark),
        500: lighten(0.4, dark),
        400: lighten(0.6, dark),
        300: lighten(0.7, dark),
        200: lighten(0.75, dark),
        100: "#fafafa"
    },

    text: {
        dark,
        normal: lighten(0.4, dark),
        light,
        link: info
    }
};
export const sizes = {
    borders: {
        radius: 3,
        width: 1.5
    },
    padding: {
        table: {
            normal: "7px 5px",
            compact: "20px 5px"
        },
        input: "1rem 0.8rem",
        select: ".6em 2.2em .5em .8em"
    },

    animations: {
        duration: 250,
        slider: {
            speed: 1000,
            autoplaySpeed: 5000
        }
    },

    icons: {
        size: 12
    },

    fonts: {
        heading: {
            h1: "2.5rem",
            h2: "2rem",
            h3: "1.75rem",
            h4: "1.5rem",
            h5: "1.25rem",
            h6: "1rem"
        },
        text: "1rem"
    },

    skeleton: {
        height: "1em",
        width: "6em"
    }
};
